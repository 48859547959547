import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS: Menu[] = [
  {
    state: 'manager',
    name: 'Dashboard',
    type: 'link',
    icon: 'insights',
  },
  {
    state: 'prototyper',
    name: 'Gallery Builder',
    type: 'sub',
    icon: 'loyalty',
    children: [
      { state: 'modules', name: 'My Galleries', type: 'link' },
      { state: 'assets', name: 'My Assets', type: 'link' },
      { state: 'characters', name: 'My Characters', type: 'link' },
    ]
  },
  {
    state: 'user',
    name: 'User Management',
    type: 'link',
    icon: 'accessibility',
  },
  {
    state: 'user',
    name: 'Question Bank',
    type: 'link',
    icon: 'accessibility',
  },
  {
    state: 'user/leaderboard',
    name: 'Leaderboards',
    type: 'link',
    icon: 'accessibility',
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
