<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3>Update PDF presentation</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <div style="display: flex; justify-content: space-between">
          <button mat-button (click)="delete_user_asset_document()">
            <i class="fa fa-trash"></i>
          </button>
          <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="dataDoc.name"
          maxlength="25"
          required
        >
        <mat-error *ngIf="dataDoc.name.errors?.maxlength">
          Name cannot be more than 25 characters.
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea
          matInput
          type="email"
          [(ngModel)]="dataDoc.description"
          rows="3"
        ></textarea>
      </mat-form-field>
    </div>

    <div fxFlex="100" style="margin-bottom: 10px">
      <mat-checkbox [(ngModel)]="dataDoc.default_issue"
        >Company Level</mat-checkbox
      >
    </div>

    <input
      type="file"
      accept="application/pdf"
      #fileUpload
      style="display: none"
      (change)="onChange($event)"
    />
    <div fxFlex="100" style="display: flex">
      <button
        mat-raised-button
        color="primary"
        (click)="fileUpload.click()"
        style="width: 100%"
        [disabled]="!dataDoc.name"
        class="m-r-10"
      >
        <mat-icon>upload</mat-icon>Upload PDF
      </button>
      <a
        mat-raised-button
        color="primary"
        href="{{ data.element.user_asset_document.doc_url }}"
        target="_blank"
        style="width: 100%"
      >
        <mat-icon>download</mat-icon>Download PDF
      </a>
    </div>
  </div>
</mat-dialog-content>
