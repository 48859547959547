import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-headset-carrier-modal',
  templateUrl: './headset-carrier-modal.component.html',
  styleUrls: ['./headset-carrier-modal.component.scss']
})
export class HeadsetCarrierModalComponent implements OnInit {

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<HeadsetCarrierModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

  onChange(event: any){
    this.data.carrier.id = event;
    var carrier = this.data.carriers.filter((x:any) => x.id === event)[0]
    this.data.carrier = carrier;
  }


}
