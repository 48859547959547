<div
  class="d-flex align-items-center user-card"
  *ngIf="optionKey === 'first_name'"
>
  <img
    class="mat-card-avatar"
    [ngClass]="{ small: size == 'small' }"
    [src]="userDetail.profile_image ? userDetail.profile_image : userDetail.avatar_read_url"
  />
  <div>
    <div class="user-card__name">
      {{ userDetail.first_name }} {{ userDetail.last_name }}
    </div>
    <div class="user-card__description">
      {{ roles }}
    </div>
  </div>
</div>

<div
  class="d-flex align-items-center user-card"
  *ngIf="optionKey === 'group_name'"
>
  <div class="">
    <div class="user-count" [ngClass]="{ small: size == 'small' }">
      {{ userDetail.user_count || 0 }}
    </div>
  </div>
  <div fxFlex="80">
    <div class="user-card__name">
      {{ userDetail.group_name }}
    </div>
    <div class="user-card__description">
      {{ userDetail.manager.first_name }} {{ userDetail.manager.last_name }}
    </div>
  </div>
</div>
