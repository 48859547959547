<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3 *ngIf="data.id">Update Headset Order</h3>
        <h3 *ngIf="!data.id">Add Headset Order</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Headset Type</mat-label>
        <mat-select
          [(ngModel)]="data.headset.headset_name.id"
          [disabled]="data.id"
        >
          <mat-option *ngFor="let t of data.headsets_available" [value]="t.id"
            >{{ t.name }} - ({{ t.supplier_details.name }})</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Quantity</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="data.headset.quantity_purchased"
        />
        <mat-hint align="start" *ngIf="data.headset.headset_name.id"
          >Min Quantity:
          {{ getMinQuantity(data.headset.headset_name.id) }}</mat-hint
        >
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <button
        mat-raised-button
        color="primary"
        (click)="closeDialog(data.headset.headset_name.id)"
        style="width: 100%; margin-top: 16px"
      >
        <mat-icon>save</mat-icon>Save Headset Details
      </button>
    </div>
  </div>
</mat-dialog-content>
