import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { getPogresBarConfig } from './constants';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {
  @Input() value: number;
  @Input() total: number;

  public chartOptions: any;
  constructor() {}

  ngOnInit(): void {
    this.chartOptions = getPogresBarConfig(this.value, this.total);
  }
}
