<div class="responsive-table">
    <mat-progress-bar
      mode="indeterminate"
      class="m-t-20"
      *ngIf="loading"
    ></mat-progress-bar>

    <div fxFlex="100">
      <h5>Active Licenses</h5>
      <mat-divider
        style="margin-top: 0; padding-top: 0"
        inset="true"
      ></mat-divider>
    </div>

    <div style="width: 50%">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
        <mat-form-field>
          
          <mat-label
            >Search active master license by module, issue date, expiry date,
            max users, current users, status or payment method
          </mat-label>
          
          <input
            matInput
            style="padding-bottom: 1px"
            type="text"
            [(ngModel)]="searchMasterLicense"
            name="searchMasterLicense"
          />
          
          <button
            *ngIf="searchMasterLicense"
            type="button"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            style="margin-right: 10px"
          >
            Search
          </button>
        </div>
      </form>
    </div>

    <mat-table [dataSource]="dataSource" matSort>
      
      <ng-container matColumnDef="module">
        <mat-header-cell *matHeaderCellDef> Master License </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.different_name_format">
            {{ element.name }}
          </ng-container>
          <ng-container *ngIf="!element.different_name_format">
            {{ element.module.name }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="issue">
        <mat-header-cell *matHeaderCellDef> Issue Date </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.date_created | date : "dd/MM/YY" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expiry">
        <mat-header-cell *matHeaderCellDef> Expiry Date </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.expiry">{{
            element.expiry | date : "dd/MM/YY"
          }}</ng-container
          ><ng-container *ngIf="!element.expiry">-</ng-container></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="max_users">
        <mat-header-cell *matHeaderCellDef> Max Users </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.maximum_issue">
            {{ element.maximum_issue }}
          </ng-container>
          <ng-container *ngIf="!element.maximum_issue"> - </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="current_users">
        <mat-header-cell *matHeaderCellDef> Current Users </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.assigned_licenses_count }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="payment">
        <mat-header-cell *matHeaderCellDef> Payment </mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><ng-container *ngIf="element.payment_method">{{
            element.payment_method.name
          }}</ng-container
          ><ng-container *ngIf="!element.payment_method"
            >-</ng-container
          ></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="checkActive(element.id) && element.id">
            <i class="fa fa-check-circle" style="color: green"></i>
          </ng-container>
          <ng-container *ngIf="!checkActive(element.id) && element.id">
            <i class="fa fa-times-circle" style="color: red"></i>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element" class="action-link">
          
          <button
            mat-mini-fab
            class="m-r-10"
            color="primary"
            (click)="editLicense(element)"
          >
            <i class="fa fa-pencil"></i>
          </button>

          <button
            mat-mini-fab
            class="m-l-10 m-r-10"
            color="warn"
            (click)="suspendLicense(element)"
            *ngIf="checkActive(element.id, true)"
          >
            <i class="fa fa-ban"></i>
          </button>

          <button
            mat-mini-fab
            class="m-l-10 m-r-10"
            color="primary"
            *ngIf="!checkActive(element.id, true)"
            (click)="activateLicense(element)"
          >
            <i class="fa fa-undo"></i>
          </button>

          <button
            mat-mini-fab
            class="m-l-10"
            color="accent"
            (click)="deleteLicense(element)"
          >
            <i class="fa fa-trash"></i>
          </button>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>

    <mat-paginator
      #paginator
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 25, 50]"
      (page)="pageChanged($event, true)"
    ></mat-paginator>
  </div>