export const getPogresBarConfig = (value: number, total: number, primaryColor:any = "#ff00c2"): any => {
  return {
      series: [(value / total) * 100],
      chart: {
        height: 70,
        type: "radialBar",
        colors: ['#000']
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%"
          },
          dataLabels: {
            name: {
              color: "#111",
              fontSize: "12px",
              show: true,
              offsetY: 5
            },
            value: {
              show: false
            }
          }
     
        }
      }, 
      fill: {
        type: 'solid',
        colors: ['#ff00c2'],
        opacity: 1
      },
      labels: [value+'/'+total]
    };
};
