<div class="main-container">
    <mat-toolbar color="primary" class="topbar relative">
        <div class="w-100 fix-width-for-horizontal">
            <div class="navbar-header" style="margin: 0 auto;">
                <a class="navbar-brand" href="/">
                    <b>
                        <img src="assets/images/xelerate-logo.png" alt="homepage" class="dark-logo">
                        <img src="assets/images/xelerate-logo.png" class="light-logo" style="width: 213px;">
                    </b>
                </a>
            </div>
        </div>
    </mat-toolbar>
    <mat-sidenav-container  class="page-wrapper">
        <div class="page-content-auth">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-container>
</div>