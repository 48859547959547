/**

**/
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { UserService } from '../../_services/user.service';
import { AuthenticationFinalResponse } from '../../_models/user_authentication';
import { MatSnackBar } from '@angular/material/snack-bar';

/** @title Responsive sidenav */
@Component({
	selector: 'app-full-layout',
	templateUrl: 'full.component.html',
	styleUrls: []
})
export class FullComponent implements OnDestroy {
	mobileQuery: MediaQueryList;

	public today = new Date();
	public date_formats: string[] = ["dd/MM/yyyy HH:mm", "dd/MM/yyyy h:mm a", "MM/dd/yyyy HH:mm", "MM/dd/yyyy h:mm a"];

	public user !: AuthenticationFinalResponse;
	public modified_user: boolean = false;

	public green: boolean = false;
	public blue: boolean = false;
	public danger: boolean = false;

	public showHide: boolean = false; 
	public sidebarOpened: boolean = false;
	public status: boolean = false;

	public showSearch = false;
	public config: PerfectScrollbarConfigInterface = {};

	public settingChanged: boolean = false;

	private _mobileQueryListener: () => void;

	constructor(
		public router: Router,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public menuItems: MenuItems,
		public translate: TranslateService,
		public _user: UserService,
		private _snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute
	) {
		//this.user.attributes.platform_settings.direction = 'ltr';
		//this.user.attributes.platform_settings.boxed_layout = false;
		//this.user.attributes.platform_settings.mini_sidebar = false;
		//this.user.attributes.platform_settings.horizontal_layout = false;
		this.mobileQuery = media.matchMedia('(min-width: 1023px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();

		this.mobileQuery.addListener(this._mobileQueryListener);
		this._user.currentUserDetails.subscribe((data:AuthenticationFinalResponse) => {
			this.user = data;			
			this.translate.use(this.user.attributes.platform_settings.language);
		});
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}

	languageHumanize(code: string){

    	const languages: any = {
      		en : {name: 'English'},
      		fr : {name: 'Français'},
      		de : {name: 'Deutsche'},
      		es: {name: 'Español'},
      		zh: {name: '正體字'},
    	}

    	let language = languages[code]
		return language.name
  	}

	ngOnInit() {
	}

	testRouter(): void {
		const test_array = ['','material','manager','performance','prototyper']
		var rout = this.router.config
		if (rout[0].children)
			rout[0].children = rout[0].children.filter(x => test_array.includes(x.path!))

		console.log(rout[0].children)
		this.router.resetConfig(rout)
	}

	clickEvent(): void {
		 this.status = !this.status;
	}

	updateUserSettings(isOpen: boolean): void {
		if (isOpen && this.settingChanged) {
			this._user.changeUserDetails(this.user);
			this._user.updateUserAPI(this.user.attributes, true).subscribe(
				(data:any) => {
					this._snackBar.open(
						'Settings successfully updated', 
						'Close', 
						{
							duration: 2000,
							horizontalPosition: 'center',
      						verticalPosition: 'top',

						});
				},
				(error:any) => {console.log('urgh')}
			)
		}
	}

	darkClick() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.toggle('dark');
	}

	openLink() {
		window.open("https://platform.e-learningstudios.com/authentication/profiles");
	}

}
