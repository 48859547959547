import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subdomain-modal',
  templateUrl: './subdomain-modal.component.html',
  styleUrls: ['./subdomain-modal.component.scss']
})
export class SubdomainModalComponent implements OnInit {

  public domains: string[] = ['Xelerate', 'XRL Platform', 'E-Learning Studios'];

  constructor(
    public _dialogRef: MatDialogRef<SubdomainModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  checkValues() {
    if (this.data.domain && this.data.subdomain){
      return false
    } else {
      return true
    }
  }

  closeDialog() {
    if (this.data.domain === 'Xelerate') {
      this.data.url = this.data.subdomain + '.xeleratelearning.com';
    }

    if (this.data.domain === 'XRL Platform') {
      this.data.url = this.data.subdomain + '.xrlplatform.com';
    }

    if (this.data.domain === 'E-Learning Studios') {
      this.data.url = this.data.subdomain + '.e-learningstudios.com';
    }

    this.data.date_created = new Date()
    this.data.server = "Unassigned"

    this._dialogRef.close({data: this.data});
  }

}
