<div fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
	<ng-container *ngFor="let holder of placeholders; index as i">
		<div [fxFlex]="entryWidth">
			<mat-form-field appearance="fill">
				<input #input matInput 
					style="text-align: center;"
					(click)="onClick($event)"
         			(paste)="onPaste($event, i)"
         			(input)="onInput($event, i)"
         			(keydown)="onKeydown($event, i)"
                    [disabled]="disablePIN"
				>
			</mat-form-field>
		</div>
	</ng-container>
</div>