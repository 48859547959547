import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

	constructor(
		public translate: TranslateService,
    private router: Router,
    private _auth: AuthenticationService
	){
		  translate.addLangs(['en', 'es', 'de', 'fr', 'zh']);
    	translate.setDefaultLang('en');
    	const browserLang = translate.getBrowserLang();
    	translate.use(browserLang.match(/en|fr|es|de|zh/) ? browserLang : 'en');
      //this._auth.authorizeRenewal().subscribe((data:any) => {console.log(data)})
	}

}
