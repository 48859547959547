import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { UserService } from '../../_services/user.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PlatformService } from 'src/app/_services/platform.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-lms',
  templateUrl: './new-lms.component.html',
  styleUrls: ['./new-lms.component.scss'],
})
export class NewLmsComponent implements OnInit {
  constructor(
    private _user: UserService,
    private _platform: PlatformService,
    private _snackBar: MatSnackBar,
    public _dialogRef: MatDialogRef<NewLmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this._dialogRef.close({ data: this.data });
  }

  private triggerChange(param: any = null): void {
    if (typeof this.data.onChange === "function") {
      this.data.onChange(param);
    }
  }

  newLMS() {
    this._platform
          .createLMS(parseInt(this.data.company_id), this.data.name)
          .subscribe(
            (data: any) => {
              this.triggerChange({ action: "addLMS" });
              this._dialogRef.close();
              this._snackBar.open('LMS created.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open('Error LMS not created.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            }
          );
  }
}
