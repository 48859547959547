<div fxLayout="row wrap">
  <div fxFlex="100">
    <h3>Platform Appearance</h3>
    <mat-divider style="margin-top: 0; padding-top: 0" inset="true"></mat-divider>
  </div>
  <div fxFlex="100">
    <p>
      The display settings for {{ companyName }} are shown below, the primary
      logo is always shown on a background of the primary colour. The secondary
      logo is used for favicons and is always used on a white background.
      Colours can be selected using the colour picker or by entering the Hex
      code. The colour scheme will update with the client next updates if they
      do not have a custom sub-domain.
    </p>
  </div>
  <ng-container *ngIf="platformData">
    <input type="file" style="display: none" #fileUploadPrimary (change)="onFileSelected($event, 'primary')" />
    <input type="file" style="display: none" #fileUploadSecondary (change)="onFileSelected($event, 'secondary')" />
    <div fxFlex="50" align="center" class="color-card" style="padding-right: 20px" (click)="fileUploadPrimary.click()">
      <h4>Primary Logo</h4>
      <mat-card>
        <mat-card-content [ngStyle]="{
            heigth: '63px',
            'background-color': platformData.primary_color,
            'padding-top': '10px'
          }">
          <img [src]="platformData?.logos?.primary" style="height: 43px" />
        </mat-card-content>
        <mat-card-footer *ngIf="primaryUploading">
          <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
    <div fxFlex="50" align="center" class="color-card" style="padding-left: 20px" (click)="fileUploadSecondary.click()">
      <h4>Secondary Logo</h4>
      <mat-card>
        <mat-card-content style="height: 63px; padding-top: 10px">
          <img [src]="platformData?.logos?.secondary" style="height: 43px" />
        </mat-card-content>
        <mat-card-footer *ngIf="secondaryUploading">
          <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
    <div fxFlex="33.33" align="center" class="color-card" style="padding-right: 20px">
      <h4>Primary Colour</h4>
      <mat-card>
        <mat-card-content [ngStyle]="{
            heigth: '40px',
            'background-color': platformData.primary_color
          }">
        </mat-card-content>
      </mat-card>
      <div style="margin-top: 16px">
        <ngx-colors ngx-colors-trigger [(ngModel)]="platformData.primary_color"
          (input)="this.debouceColorChange.next($event)">
        </ngx-colors>
      </div>
    </div>
    <div fxFlex="33.33" align="center" class="color-card"
      style="text-align: center; padding-right: 20px; padding-left: 20px">
      <h4>Accent Colour</h4>
      <mat-card>
        <mat-card-content [ngStyle]="{
            heigth: '40px',
            'background-color': platformData.secondary_color
          }">
        </mat-card-content>
      </mat-card>
      <div style="margin-top: 16px">
        <ngx-colors ngx-colors-trigger [(ngModel)]="platformData.secondary_color"
          (input)="this.debouceColorChange.next($event)">
        </ngx-colors>
      </div>
    </div>
    <div fxFlex="33.33" align="center" class="color-card" style="text-align: center; padding-left: 20px">
      <h4>Warn Colour</h4>
      <mat-card>
        <mat-card-content [ngStyle]="{
            heigth: '40px',
            'background-color': platformData.warn_color
          }">
        </mat-card-content>
      </mat-card>
      <div style="margin-top: 16px">
        <ngx-colors ngx-colors-trigger [(ngModel)]="platformData.warn_color"
          (input)="this.debouceColorChange.next($event)">
        </ngx-colors>
      </div>
    </div>
  </ng-container>
  <div fxFlex="100" class="m-t-20">
    <h3>Sub Domains</h3>
    <mat-divider style="margin-top: 0; padding-top: 0" inset="true"></mat-divider>
  </div>
  <div fxFlex="100" *ngIf="companyName">
    <p>
      All sub domains for {{ companyName }} are shown below. To create a new
      sub domain click the Add Sub Domain button, the server will be
      automatically assigned. If you select Xelerate the sub domain will be
      mapped to xeleratelearning.com, xeleratelearning.co.uk and xelerate.xyz.
      The sub domain will be available to {{ companyName }} within 5 minutes. To
      edit a sub domain click the <i class="fa fa-pencil"></i> icon and to
      delete a sub domain click the
      <i class="fa fa-trash text-danger"></i> icon. Deleting a domain will
      immediately remove its mapping from the DNS.
    </p>
  </div>
  <div fxFlex="100" class="m-t-10">
    <app-subdomains [company]="company"></app-subdomains>
  </div>
</div>