<form class="example-form" fxFlex="100">
  <mat-form-field class="example-full-width">
    <mat-label>{{ completePlaceholder }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="
        selectionChange ? selectionChange($event.option.value) : null
      "
    >
      <ng-container *ngIf="isMultiple; else elseTemplate">
        <mat-option
          *ngFor="let option of options"
          [value]="option"
          [disabled]="userIds.includes(option.id)"
        >
          <div (click)="optionClicked($event, option)">
            <mat-checkbox
              [checked]="userIds.includes(option.id)"
              (change)="toggleSelection(option)"
              (click)="$event.stopPropagation()"
            >
              <app-user-card
                [userDetail]="option"
                [optionKey]="optionKey"
                [size]="'small'"
              ></app-user-card>
            </mat-checkbox>
          </div>
        </mat-option>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-option
          style="height: auto; padding-top: 10px"
          *ngFor="let option of options"
          [value]="option"
          [disabled]="userIds.includes(option.id)"
        >
          <app-user-card
            [userDetail]="option"
            [optionKey]="optionKey"
            [size]="'small'"
          ></app-user-card>
        </mat-option>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>
</form>
