import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-asset-bundle',
  templateUrl: './create-asset-bundle.component.html',
  styleUrls: ['./create-asset-bundle.component.scss']
})
export class CreateAssetBundleComponent implements OnInit {

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<CreateAssetBundleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  { 
  }

  ngOnInit(): void {
  }


  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

}
