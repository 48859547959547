import { Component, OnInit } from '@angular/core';
import { Branding } from 'src/app/_models/branding';
import { PlatformService } from 'src/app/_services/platform.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  public today: Date = new Date();

  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {}
  
  get theme(): Branding {
    return this.platformService.branding;
  }
}
