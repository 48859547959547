export const getPogresBarConfig = (value: number, total: number): any => {
  return {
    chart: {
      height: 50,
      type: 'bar',
      stacked: true,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '20%',
        colors: {
          backgroundBarColors: ['#CCC']
        }
      },
    },
    colors: ['#41a800'],
    dataLabels: {
      enabled: true,
      formatter: (dataLabel: any) => dataLabel + '%',
      offsetY: -15,
      offsetX: 10,
      style: {
        colors: ['#000000'],
        fontSize: '12px',
        fontWeight: 'normal',
      }
    },
    fill: {
      colors: ['#41a800', '#ff00c2'],
      opacity: 1
  },
    stroke: {
      width: 0,
    },
    series: [{
      data: [value],
    }],
    tooltip: {
      enabled: false
    },
    yaxis: {
      max: 100
    },
  };
};
