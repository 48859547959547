<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
      <div fxFlex="100" style="text-align:right;">
          <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div> 
      <div fxFlex="100">
         <h3>Updating Asset Bundle</h3>
      </div>
      <div fxFlex="49">
        <mat-form-field>
          <mat-label>Major Version Number</mat-label>
            <input matInput type="number" [(ngModel)]="data.major_version_number">
          </mat-form-field>
      </div>
      <div fxFlex="2">
      	&nbsp;
      </div>
      <div fxFlex="49">
        <mat-form-field>
            <mat-label>Minor Version Number</mat-label>
            <input matInput type="number" [(ngModel)]="data.minor_version_number">          
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <button mat-raised-button color="primary" style="width:100%" (click)="closeDialog()"><mat-icon>update</mat-icon> Update Asset Bundle</button>
      </div>
    </div>
</mat-dialog-content>
