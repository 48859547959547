import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmPopupModalComponent } from '../confirm-popup-modal/confirm-popup-modal.component';

@Component({
  selector: 'app-document-modal-update',
  templateUrl: './document-modal-update.component.html',
  styleUrls: ['./document-modal-update.component.scss']
})
export class DocumentModalUpdateComponent implements OnInit {

  public file !:File;
  public uploadURL: string = "";

  public dataDoc: any = {
    id: this.data.element.user_asset_document.uuid,
    name: this.data.element.user_asset_document.name,
    description: this.data.element.user_asset_document.description,
    extension: this.data.element.user_asset_document.extension,
    default_issue: this.data.element.user_asset_document.default_issue,
  }

  // @Input() data: any;
  @ViewChild('fileUpload') fileUpload: ElementRef = Object.create(null);

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<DocumentModalUpdateComponent>,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  {}

  ngOnInit(): void {
  }

  onChange(event: any) {
    this.file = event.target.files[0];
    this.dataDoc.extension = this.file.name.split('.')[this.file.name.split('.').length - 1];
    
    this._dialogRef.close({data: this.dataDoc, file: this.file});
  }

  delete_user_asset_document() {
    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: this.data.element.user_asset_document.uuid,
        title: 'Delete document',
        delete: 'Would you like to Delete document?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this._user.deleteUserAssetDoc(this.data.element.user_asset_document.uuid).subscribe((data: any) => {
          this._snackBar.open('Document deleted.', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
          this.data.parent.getMasterLicense(); 
        }, (error: any) => {
          this._snackBar.open('Document delete failed.', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
        })
        this._dialogRef.close();
        
      }});
  }

}
