<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3 *ngIf="data.id">Edit Master License</h3>
        <h3 *ngIf="!data.id">Create Master License</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>

    <!-- Edit start -->
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Module</mat-label>
        <mat-select [(ngModel)]="data.module.id" [disabled]="data.id">
          <mat-option *ngFor="let t of data.modules_available" [value]="t.id">
            <div
              class="d-flex align-items-center lic-card"
            >
              <img
                class="mat-card-avatar"
                [ngClass]="small"
                [src]="t.get_icon_read_url"
              />
              <div>
                <div class="lic-card__name">
                  {{ t.name }} 
                </div>
                <!-- <div class="lic-card__description">
                  Expires: {{ t.expiry }}
                </div> -->
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Edit end -->

    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Expiry</mat-label>
        <input
          matInput
          [matDatepicker]="datepicker"
          [(ngModel)]="data.expiry"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datepicker> </mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Max Users</mat-label>
        <input matInput type="number" [(ngModel)]="data.maximum_issue" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Payment</mat-label>
        <mat-select [(ngModel)]="data.payment_method">
          <mat-option *ngFor="let pay of payment_terms_client" [value]="pay">
            {{ pay }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        (click)="closeDialog()"
        *ngIf="data.id"
      >
        <mat-icon>update</mat-icon>Update License
      </button>
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        (click)="closeDialog()"
        *ngIf="!data.id"
      >
        <mat-icon>add_shopping_cart</mat-icon>Create License
      </button>
    </div>
  </div>
</mat-dialog-content>
