import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-boolean-view',
  templateUrl: './boolean-view.component.html',
  styleUrls: ['./boolean-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanViewComponent implements OnInit {
  @Input() value: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
