import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { getPogresBarConfig } from './constants';
import { Branding } from 'src/app/_models/branding';
import { PlatformService } from 'src/app/_services/platform.service';

@Component({
  selector: 'app-radial-bar',
  templateUrl: './radial-bar.component.html',
  styleUrls: ['./radial-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadialBarComponent implements OnInit {
  @Input() value: number;
  @Input() total: number;
  public chartOptions: any;

  constructor(private platformService: PlatformService) { }

  ngOnInit(): void {
    this.chartOptions = getPogresBarConfig(this.value, this.total, this.theme?.appCustomisation?.primary_color);
  }
  get theme(): Branding {
    return this.platformService.branding;
  }
}
