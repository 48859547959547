import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private http: HttpClient) {}

  getNotes(category: string, id: string, limit:number,offset:number) {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/notes/${id}/?category=${category}&limit=${limit}&offset=${offset}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  postNote(
    note: string,
    company: string | null,
    application: string | null,
    elearning: string | null,
    support: string | null
  ) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/notes/`, {
        note,
        company,
        application,
        elearning,
        support,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  deleteNote(id: any) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/company/notes/${id}/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
}
