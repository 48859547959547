import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { AuthGuard } from './_guards/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: '/manager/dashboard',
                pathMatch: 'full'
            },
            {
                path: 'manager',
                loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule)
            },
            {
                path: 'commercial',
                loadChildren: () => import('./commercial/commercial.module').then(m => m.CommercialModule)
            },
            {
                path: 'support',
                loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
            },
            {
                path: 'technical',
                loadChildren: () => import('./technical/technical.module').then(m => m.TechnicalModule)
            }
        ],
    },
    {
        path: 'authentication',
        component: BlankComponent,
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    }
];
