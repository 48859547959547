interface Dictionary<T = any> {
  [key: string]: T;
}

/**
 * not @Injectable as localStorage and sessionStorage are different storages and should be emulated also like different storages.
 */
export class MemoryStorageService implements Storage {
  private storage: Dictionary<string> = {};

  constructor() {
  }

  get length(): number {
    return Object.keys(this.storage).length;
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  getItem(key: string): string | null {
    return this.storage[key] || null;
  }

  removeItem(key: string): void {
    delete this.storage[key];
  }

  clear(): void {
    this.storage = {};
  }

  key(index: number): string | null {
    const keys = Object.keys(this.storage);

    return index >= 0 && keys.length < index ? keys[index] : null;
  }
}
