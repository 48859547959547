import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../_services/user.service';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-master-license-modal',
  templateUrl: './master-license-modal.component.html',
  styleUrls: ['./master-license-modal.component.scss']
})
export class MasterLicenseModalComponent implements OnInit {

  public payment_terms_client: string[] = ['Demo', '30 Days Invoice (Manual)','60 Days Invoice (Manual)','90 Days Invoice (Manual)', 'In App', 'Card']
  optionKey = "first_name";

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<MasterLicenseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  {

  }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

}
