import { Component, Input, OnInit } from '@angular/core';

enum LOADER_SIZE {
  small = "small",
  large = "large",
}

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() size: LOADER_SIZE = LOADER_SIZE.large;

  diameter: number;

  constructor() {}

  ngOnInit(): void {

    if (this.size == LOADER_SIZE.large) {
      this.diameter = 40;
    } else if (this.size == LOADER_SIZE.small) {
      this.diameter = 20;
    } else {
      this.diameter = 40;
    }
  }
}
