import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';

import { UserService } from '../../../_services/user.service';
import { AuthenticationFinalResponse } from '../../../_models/user_authentication';

import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Menu } from '../../../_models/user_authentication';
import { LocalStorageService, StorageKey } from 'src/app/_services/storage';
import { Router } from "@angular/router";
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.component.scss'],
})
export class VerticalAppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  public user!: AuthenticationFinalResponse;
  public menuItems: Menu[] = [];

  private setMenuItems(): void {
    if (this.authService.isRoleLearner) {
      this.menuItems = [
        {
          icon: 'insights',
          name: 'Dashboard',
          type: 'link',
          state: 'manager/dashboard',
          roleAccess: this.getHideFlag(),
        },
        {
          icon: 'insights',
          name: 'Data Dashboards',
          type: 'link',
          state: 'manager/data',
          roleAccess: true,
        },
        {
          icon: 'business',
          name: 'Users',
          type: 'link',
          state: 'administration/users',
          roleAccess: this.getHideFlag(),
        },
        {
          icon: 'business',
          name: 'Organisation',
          type: 'link',
          state: 'administration/company',
          roleAccess: this.getHideFlag(),
        },
        {
          icon: 'support',
          name: 'Support Tickets',
          type: 'link',
          state: 'support/tickets',
          roleAccess: true,
        },
      ];
    } else {
      this.menuItems = [
        {
          icon: 'insights',
          name: 'Dashboard',
          type: 'sub',
          state: 'manager',
          roleAccess: true,
          children: [
            {
              state: 'dashboard',
              name: 'Dashboard',
              type: 'link',
              roleAccess: this.getHideFlag(),
            },
            {
              state: 'data',
              name: 'Data Dashboards',
              type: 'link',
              roleAccess: true,
            }
          ],
        },
        {
          icon: 'business',
          name: 'Commercial',
          type: 'sub',
          state: 'commercial',
          roleAccess: this.getHideFlag(),
          children: [
            {
              state: 'clients',
              name: 'Clients',
              type: 'link',
              roleAccess: this.getHideFlag(),
            },
            {
              state: 'suppliers',
              name: 'Suppliers',
              type: 'link',
              roleAccess: this.getHideFlag(),
            },
          ],
        },
        {
          icon: 'storefront',
          name: 'Technical',
          type: 'sub',
          state: 'technical',
          roleAccess: this.getHideFlag(),
          children: [
            {
              state: 'assets',
              name: 'VR Asset Bundles',
              type: 'link',
              roleAccess: true,
            },
            {
              state: 'elearning',
              name: 'E-learning',
              type: 'link',
              roleAccess: true,
            },
            { state: 'lms', name: 'LMS Status', type: 'link', roleAccess: true },
            {
              state: 'subdomains',
              name: 'Sub Domains',
              type: 'link',
              roleAccess: true,
            },
            { state: 'servers', name: 'Servers', type: 'link', roleAccess: true },
          ],
        },
        {
          icon: 'support',
          name: 'Support',
          type: 'sub',
          state: 'support',
          roleAccess: true,
          children: [
            {
              state: 'tickets',
              name: 'Support Tickets',
              type: 'link',
              roleAccess: true,
            },
          ],
        },
      ];
    }
  }


  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _router: Router,
    public _user: UserService,
    protected authService: AuthenticationService,
    private localStorageService: LocalStorageService //public menuItems: MenuItems,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    this._user.currentUserDetails.subscribe(
      (data: AuthenticationFinalResponse) => {
        this.user = data;
        this.setMenuItems();
      }
    );
    var profile = JSON.parse(sessionStorage.getItem('current_profile') || '{}');
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getHideFlag(): boolean {
    const currentUser: any = this.localStorageService.getItem(
      StorageKey.userDetail
    );
    return !currentUser?.hide_view;
  }

  navigateTo() {
    this._router.navigate(['/user/myprofile']);
  }
}
