import { StorageKey } from './storage-key.enum';

export abstract class AbstractStorage implements Storage {
  get length(): number {
    return this.storage.length;
  }

  protected constructor(readonly storage: Storage) {
  }

  clear(): void {
    this.storage.clear();
  }

  getItem<T>(key: StorageKey): T {
    const value = this.storage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      console.warn(`Error is controlled. Value by key '${key}' is not compatible.\n`, e);

      // todo: a workaround for backward compatibility. change this logic to `return null` after several releases.
      return value as null;
    }
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: StorageKey): void {
    this.storage.removeItem(key);
  }

  setItem<T>(key: StorageKey, value: T): void {
    value = typeof value === 'undefined' ? null : value;

    this.storage.setItem(key, JSON.stringify(value));
  }
}
