<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3 *ngIf="data.id">Edit Contact</h3>
        <h3 *ngIf="!data.id">Create Contact</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="email" [(ngModel)]="data.name" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Department</mat-label>
        <input matInput type="email" [(ngModel)]="data.department" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" [(ngModel)]="data.email" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Telephone</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'gb']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="telephone"
          [(ngModel)]="data.phonenumber"
          #telephone
        >
        </ngx-mat-intl-tel-input>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'gb']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          name="mobile"
          [(ngModel)]="data.mobile"
          #mobile
        >
        </ngx-mat-intl-tel-input>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Address 1</mat-label>
        <input matInput type="email" [(ngModel)]="data.address_1" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Address 2</mat-label>
        <input matInput type="email" [(ngModel)]="data.address_2" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Address 3</mat-label>
        <input matInput type="email" [(ngModel)]="data.address_3" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Address 4</mat-label>
        <input matInput type="email" [(ngModel)]="data.address_4" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Postcode</mat-label>
        <input matInput type="email" [(ngModel)]="data.postcode" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput type="email" [(ngModel)]="data.country" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-slide-toggle [(ngModel)]="data.primary"
        >Primary Contact</mat-slide-toggle
      >
    </div>
    <div fxFlex="100">
      <mat-slide-toggle [(ngModel)]="data.billing"
        >Billing Contact</mat-slide-toggle
      >
    </div>
    <div fxFlex="100">
      <mat-slide-toggle [(ngModel)]="data.technical"
        >Technical Contact</mat-slide-toggle
      >
    </div>
    <div fxFlex="100" class="m-t-10">
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        *ngIf="data.id"
        (click)="editContact()"
      >
        <mat-icon>update</mat-icon> Update Contact
      </button>
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        *ngIf="!data.id"
        (click)="addContact()"
      >
        <mat-icon>person_add</mat-icon> Add Contact
      </button>
    </div>
    <app-loader  *ngIf="isLoading"  [size]="'large'"></app-loader>
  </div>
</mat-dialog-content>
