<ng-container >
  <div
    fxFlexLayout="row wrap"
    fxLayoutAlign="center center"
    class="load-overlay"
    style="position: absolute;"
  >
    <div fxFlex="100" class="text-center">
      <mat-spinner color="primary" class="spinner" [ngClass]="{'spinner-small': size === 'small'}" [diameter]="diameter"></mat-spinner>
      <h2 class="loading text-muted" *ngIf="size === 'large'">Loading data ...</h2>
      <h4 class="loading text-muted" *ngIf="size === 'small'">Loading data ...</h4>
    </div>
  </div>
</ng-container>

