import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { MasterLicense } from '../_models/user_authentication';

@Injectable({
  providedIn: 'root'
})
export class MasterLicenseService {

  constructor(
    private http: HttpClient,
  ) { }

  getMasterLicense(id: number | null, limit: number, offset: number, query: string=null, option: string = null, isActive: boolean = true): any {
    if (id) {
      return this.http.get<MasterLicense[]>(`${environment.authUrl}/v1/license_management/masterlicense/${id}/?limit=${limit}&offset=${offset}&query=${query}&option=${option}&isActive=${isActive}`).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!")));
    } else {
      return this.http.get<MasterLicense[]>(`${environment.authUrl}/v1/license_management/masterlicense/?limit=${limit}&offset=${offset}&isActive=${isActive}`).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!")));
    }
  }

  getAppsList(): any {
    return this.http.get<any>(`${environment.authUrl}/v1/license_management/applications/`).pipe(
      map(response => {
        return response
      }),
      catchError(error => throwError(error || "Something went wrong!")));
  }


  getLicenseBundles(id: number | null, limit: number = 10, query: string=null): any {
    if (id) {
      return this.http.get<any[]>(`${environment.authUrl}/v1/license_management/bundles/${id}/?limit=${limit}&query=${query}`).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!")));
    } else {
      return this.http.get<any>(`${environment.authUrl}/v1/license_management/bundles/?limit=${limit}`).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!")));
    }
  }

  createLicenseBundle(name: string, apps: string[], default_issue: boolean, id: number | null) {
    if (id) {
      return this.http.post<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/`, { name, apps, default_issue }).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!"))
      )
    } else {
      return this.http.post<any>(`${environment.authUrl}/v1/license_management/bundles/`, { name, apps, default_issue }).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!"))
      )
    }
  }


  updateLicenseBundle(name: string, apps: string[], default_issue: boolean, id: number, company_id: number | null) {
    if (company_id) {
      return this.http.put<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/${company_id}/`, { name, apps, default_issue }).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!"))
      )

    } else {
      return this.http.put<any>(`${environment.authUrl}/v1/license_management/bundles/${id}`, { name, apps, default_issue }).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!"))
      )
    }
  }

  deleteLicenseBundle(id: number, company_id: number | null) {
    if (company_id) {
      return this.http.delete<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/${company_id}/`).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!"))
      )

    } else {
      return this.http.delete<any>(`${environment.authUrl}/v1/license_management/bundles/${id}`).pipe(
        map(response => {
          return response
        }),
        catchError(error => throwError(error || "Something went wrong!"))
      )
    }
  }

  createMasterLicense(module: string, expiry: Date | null, max_users: number | null, payment: string, company: string) {
    return this.http.post<any>(`${environment.authUrl}/v1/license_management/masterlicense/`, { module, expiry, max_users, payment, company }).pipe(
      map(response => {
        return response
      }),
      catchError(error => throwError(error || "Something went wrong!"))
    )
  }

  updateMasterLicense(id: string, payload: any) {
    console.log(payload);

    return this.http.put<any>(`${environment.authUrl}/v1/license_management/masterlicense/${id}/`, payload).pipe(
      map(response => {
        return response
      }),
      catchError(error => throwError(error || "Something went wrong!"))
    )
  }

  deleteMasterLicense(id: string) {

    return this.http.delete<any>(`${environment.authUrl}/v1/license_management/masterlicense/${id}/`).pipe(
      map(response => {
        return response
      }),
      catchError(error => throwError(error || "Something went wrong!"))
    )
  }

}
