<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap" class="position-relative">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3>Merge Group</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div class="w-100 " style="position: relative;">
      <div >
        <mat-form-field>
          <mat-label>Group Name</mat-label>
          <input matInput type="text" [(ngModel)]="newGroupName" required />
        </mat-form-field>
        <mat-error *ngIf="!newGroupName && isError">
          <strong>Enter group name</strong>
        </mat-error>
      </div>
      <div >
        <app-autocomplete
          [options]="companyManager"
          (onFilter)="changeHandler($event)"
          placeholder="Manager of Group"
          [optionKey]="optionKey"
          [required]="true"
          (onSelected)="getManager($event)"
        ></app-autocomplete>
        <mat-error *ngIf="!data.manager && isError">
          <strong>Please select Manager</strong>
        </mat-error>
      </div>

      <div class="table-responsive multiple-user-group">
        <table
          mat-table
          [dataSource]="data.selectedGroup || []"
          matSort
          class="table employee-list no-wrap"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Group Name
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                <div class="m-l-15">
                  <p class="font-medium m-b-0 m-t-0">
                    {{ element.group_name }}
                  </p>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div  class="m-t-20">
        <button
          mat-raised-button
          color="primary"
          style="width: 100%"
          (click)="mergeGroup()"
        >
          Merge
        </button>
      </div>
      <app-loader  *ngIf="isLoading" [size]="'large'"></app-loader>
    </div>
  </div>
</mat-dialog-content>
