import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  Input,
  QueryList,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MasterLicenseService } from '../../_services/masterlicense.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CreateSupplierOrderComponent } from '../../shared/create-supplier-order/create-supplier-order.component';
import { AuthenticationFinalResponse } from '../../_models/user_authentication';
import { LicenseBundleModalComponent } from '../license-bundle-modal/license-bundle-modal.component';
import { ConfirmPopupModalComponent } from '../confirm-popup-modal/confirm-popup-modal.component';
import { DatePipe } from '@angular/common';
import { DocumentModalComponent } from '../../shared/document-modal/document-modal.component';
import { DocumentModalUpdateComponent } from '../../shared/document-modal-update/document-modal-update.component';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-license-manager',
  templateUrl: './license-manager.component.html',
  styleUrls: ['./license-manager.component.scss'],
})
export class LicenseManagerComponent implements OnInit {
  public table_data!: any[];
  public table_data_bundle!: any[];
  public modules_available: any[] = [];
  isSearchFormSubmitted = false;
  isSearchFormSubmittedBundle = false;
  searchMasterLicense: any = '';
  searchBundle: any = '';
  public displayedColumnsBundle = [
    'name',
    'modules',
    'default_issue',
    'actions',
  ];
  public displayedColumns = [
    'module',
    'issue',
    'expiry',
    'max_users',
    'current_users',
    'status',
    'update_date',
    'actions',
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.table_data
  );
  public dataSourceBundle: MatTableDataSource<any> = new MatTableDataSource(
    this.table_data_bundle
  );

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  isLoading = true;
  loading = false;

  secondTotalRows = 0;
  secondCurrentPage = 0;
  secondPageSize = 25;
  secondOffset = 0;
  bundleloading = false;

  @Input('company') company_id: string = '1';

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  @ViewChild(MatPaginator, { static: true }) MasterPagination: MatPaginator =
    Object.create(null);
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  // @ViewChild(MatPaginator, { static: true }) licensePagination: MatPaginator =
  //   Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _masterLicense: MasterLicenseService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _user: UserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.getMasterLicense();
    this.getLicenseBundles();
  }

  getMasterLicense() {
    const datePipe = new DatePipe('en-US');
    this.loading = true;
    this._masterLicense
      .getMasterLicense(
        parseInt(this.company_id),
        this.secondPageSize,
        this.secondOffset,
        this.searchMasterLicense
      )
      .subscribe((data: any) => {
        console.log(data.detail.result);

        this.table_data = data.detail.result;
        this.modules_available = [];
        for (let t of this.table_data) {
          if (t.user_asset_document.date_updated) {
            const formattedDateUpdated = datePipe.transform(
              new Date(t.user_asset_document.date_updated),
              'yyyy-MM-dd'
            );
            t.user_asset_document.date_updated = formattedDateUpdated;
          }

          if (this.checkActive(t.id)) {
            const formattedExpiry = t.expiry
              ? datePipe.transform(new Date(t.expiry), 'yyyy-MM-dd')
              : 'Indefinite';
            t.expiry = formattedExpiry;
            this.modules_available.push(t);
          }
        }
        this.dataSource.data = this.table_data;
        this.dataSource.paginator = this.paginator.toArray()[1];
        this.dataSource.sort = this.sort;
        this.loading = false;
        setTimeout(() => {
          this.paginator.toArray()[1].pageIndex = this.secondCurrentPage;
          this.paginator.toArray()[1].length = data.detail.count;
        });
      });
  }

  getLicenseBundles() {
    this.bundleloading = true;
    this._masterLicense
      .getLicenseBundles(parseInt(this.company_id), 300, this.searchBundle)
      .subscribe((data: any) => {
        this.table_data_bundle = data.detail.result;
        this.dataSourceBundle.data = this.table_data_bundle;
        this.dataSourceBundle.paginator = this.paginator.toArray()[0];
        this.dataSourceBundle.sort = this.sort;
        this.bundleloading = false;
        setTimeout(() => {
          this.paginator.toArray()[0].pageIndex = this.currentPage;
          this.paginator.toArray()[0].length = data.detail.count;
        });
      });
  }

  ngAfterViewInit(): void {
    this.dataSourceBundle.paginator = this.paginator.toArray()[0];
    this.dataSource.paginator = this.paginator.toArray()[1];
    this.dataSource.sort = this.sort;
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getLicenseBundles();
  }

  secondPageChanged(event: PageEvent): void {
    this.secondOffset = event.pageSize * event.pageIndex;
    this.secondPageSize = event.pageSize;
    this.secondCurrentPage = event.pageIndex;
    this.getMasterLicense();
  }

  deleteBundle(element: any): void {
    // this.table_data_bundle = this.table_data_bundle.filter(
    //   (x: any) => parseInt(x.id) !== parseInt(element.id)
    // );
    // this.dataSourceBundle.data = this.table_data_bundle;

    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: element.id,
        title: 'Delete License Bundle',
        delete: 'License Bundle',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this._masterLicense
          .deleteLicenseBundle(element.id, parseInt(this.company_id))
          .subscribe(
            (data: any) => {
              this.getLicenseBundles();
              this._snackBar.open('License bundle deleted.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open(
                'Error! License bundle not deleted.',
                'Dismiss',
                {
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                  duration: 2000,
                }
              );
            }
          );
      }
    });
  }

  editBundle(element: any): void {
    const dialogRef = this._dialog.open(LicenseBundleModalComponent, {
      width: '600px',
      data: {
        modules_available: this.modules_available,
        name: element.name,
        apps: element.apps.split(', '),
        default_issue: element.default_issue,
        id: element.id,
        company_id: this.company_id,
        onChange: (params: any = null) => {
          this.getLicenseBundles();
        },
      },
    });
  }

  addLicenseBundle() {
    const dialogRef = this._dialog.open(LicenseBundleModalComponent, {
      width: '600px',
      data: {
        modules_available: this.modules_available,
        name: null,
        apps: null,
        default_issue: false,
        id: null,
        company_id: this.company_id,
        onChange: (params: any = null) => {
          this.getLicenseBundles();
        },
      },
    });
  }

  checkActive(id: string): boolean {
    let mlic = this.table_data.filter((x: any) => x.id === parseInt(id))[0];
    let date_now = new Date();
    let expiry_date = new Date(mlic.expiry);

    if (mlic.expiry) {
      let expiry_date = new Date(mlic.expiry);
      if (expiry_date < date_now) {
        return false;
      }
    }

    if (
      mlic.maximum_issue &&
      mlic.maximum_issue < mlic.assigned_licenses_count
    ) {
      return false;
    }

    return mlic.active;
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchMasterLicense = '';
      return;
    }

    this.searchMasterLicense = '';
    this.getMasterLicense();
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchMasterLicense = this.searchMasterLicense.trim();
    this.getMasterLicense();
  }

  clearSearchBundle(): void {
    if (!this.isSearchFormSubmittedBundle) {
      this.searchBundle = '';
      return;
    }

    this.searchBundle = '';
    this.getLicenseBundles();
    this.isSearchFormSubmittedBundle = false;
  }

  applySearchBundle(): void {
    this.isSearchFormSubmittedBundle = true;
    this.searchBundle = this.searchBundle.trim();
    this.getLicenseBundles();
  }

  uploadDoc(element: any) {
    const dialogRef = this._dialog.open(DocumentModalComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        if (result.data.extension != 'pdf') {
          this._snackBar.open(
            'Error, Please upload PDF file format.',
            'Dismiss',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            }
          );
        } else {
          this._user
            .addUserAssetDoc(
              result.data.name,
              result.data.description,
              result.data.extension,
              element.module.id,
              parseInt(this.company_id),
              result.data.default_issue
            )
            .subscribe(
              (data: any) => {
                this._user
                  .fileUploadService(
                    result.file,
                    `v1/license_management/document/${data.detail.uuid}`,
                    'put'
                  )
                  .subscribe(
                    (response: any) => {
                      console.log(response);
                      this.getMasterLicense();
                      this._snackBar.open('Document uploaded.', 'Dismiss', {
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                        duration: 2000,
                      });
                    },
                    (error: any) => {
                      this._snackBar.open(
                        `Error, ${error.error.message}`,
                        'Dismiss',
                        {
                          horizontalPosition: 'center',
                          verticalPosition: 'top',
                          duration: 2000,
                        }
                      );
                    }
                  );
              },
              (error: any) => {
                this._snackBar.open(
                  `Error, ${error.error.message}`,
                  'Dismiss',
                  {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    duration: 2000,
                  }
                );
              }
            );
        }
      }
    });
  }

  updateUploadDoc(element: any) {
    const dialogRef = this._dialog.open(DocumentModalUpdateComponent, {
      width: '600px',
      // data: element,
      data: { element, parent: this },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        if (result.data.extension != 'pdf') {
          this._snackBar.open(
            'Error, Please upload PDF file format.',
            'Dismiss',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            }
          );
        } else {
          this._user
            .updateUserAssetDoc(
              result.data.name,
              result.data.description,
              result.data.extension,
              element.module.id,
              result.data.id,
              parseInt(this.company_id),
              result.data.default_issue
            )
            .subscribe(
              (data: any) => {
                this._user
                  .fileUploadService(
                    result.file,
                    `v1/license_management/document/${result.data.id}`,
                    'put'
                  )
                  .subscribe(
                    (response: any) => {
                      // this.getDocument();
                      this._snackBar.open('Document uploaded.', 'Dismiss', {
                        horizontalPosition: 'center',
                        verticalPosition: 'top',
                        duration: 2000,
                      });
                    },
                    (error: any) => {
                      this._snackBar.open(
                        `Error, ${error.error.message}`,
                        'Dismiss',
                        {
                          horizontalPosition: 'center',
                          verticalPosition: 'top',
                          duration: 2000,
                        }
                      );
                    }
                  );
              },
              (error: any) => {
                this._snackBar.open(
                  `Error, ${error.error.message}`,
                  'Dismiss',
                  {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    duration: 2000,
                  }
                );
              }
            );
          this.getMasterLicense();
        }
      }
    });
  }
}
