<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3 *ngIf="data.id">Edit License Bundle</h3>
        <h3 *ngIf="!data.id">Create License Bundle</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" [(ngModel)]="data.name" />
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Master Licenses</mat-label>
        <mat-select [(ngModel)]="data.apps" multiple>
          <mat-option
            *ngFor="let t of data.modules_available"
            [value]="t.module.name"
          >
            <div class="d-flex align-items-center lic-card">
              <img
                class="mat-card-avatar"
                [ngClass]="small"
                [src]="t.module_icon"
              />
              <div>
                <div class="lic-card__name">
                  <ng-container *ngIf="t.different_name_format">
                    {{ t.name }}
                  </ng-container>
                  <ng-container *ngIf="!t.different_name_format">
                    {{ t.module.name }}
                  </ng-container>
                </div>
                <div class="lic-card__description">Expires: {{ t.expiry }}</div>
              </div>
            </div>
            <!-- {{ t.module.name }} -->
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-slide-toggle [(ngModel)]="data.default_issue"
        >Default Issue</mat-slide-toggle
      >
    </div>

    <div fxFlex="100" class="m-t-20">
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        (click)="editBundle()"
        *ngIf="data.id"
      >
        <mat-icon>update</mat-icon>Update Bundle
      </button>
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        (click)="addLinceseBundle()"
        *ngIf="!data.id"
      >
        <mat-icon>post_add</mat-icon>Create Bundle
      </button>
    </div>
  </div>
</mat-dialog-content>
