import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PlatformService } from '../../_services/platform.service';
import { UserService } from '../../_services/user.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ConfirmPopupModalComponent } from '../../shared/confirm-popup-modal/confirm-popup-modal.component';

import { LmsFileUploadComponent } from '../lms-file-upload/lms-file-upload.component';
import { NewLmsComponent } from '../new-lms/new-lms.component';

@Component({
  selector: 'app-lms-detail',
  templateUrl: './lms-detail.component.html',
  styleUrls: ['./lms-detail.component.scss'],
})
export class LmsDetailComponent implements OnInit {
  public platformData!: any;
  public primaryUploading: boolean = false;
  public secondaryUploading: boolean = false;
  public formEdit: boolean[] = [];
  public hostingOptions: string[] = [
    'Shared',
    'Shared and Dedicated',
    'Dedicated',
  ];
  public statusOptions: string[] = [
    'New',
    'Live',
    'Development',
    'QA',
    'Closed',
  ];

  public fileLMSID: number = 0;
  public fileTYPE: string = '';
  public fileNAME: string = '';
  loading = false
  @Input('company') company_id: string = '1';
  @Input('companyName') companyName!: string;
  @ViewChild('fileUpload') fileUpload: ElementRef = Object.create(null);

  constructor(
    private _platform: PlatformService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _user: UserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: any) {
    if (this.company_id) {
      this.getLMS();
    }
  }
  getLMS(): void {
    this.loading = true;
    this.formEdit = [];
    this._platform
    .getLMS(parseInt(this.company_id))
    .subscribe((data: any) => {          
      this.loading = false;
      for (let i of data.detail.result) {
        this.formEdit.push(false);
      }
      this.platformData = data.detail.result;
    });
  }

  updateLMS(lms: any) {
    this._platform
      .updateLMS(
        lms.primary_colour,
        lms.accent_colour,
        lms.warn_colour,
        lms.domain,
        lms.hosting_model,
        lms.status,
        lms.plan_expiry,
        lms.name,
        parseInt(lms.id)
      )
      .subscribe(
        (data: any) => {
          this._snackBar.open('LMS settings updated.', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
        },
        (error: any) => {
          this._platform
            .getLMS(parseInt(this.company_id))
            .subscribe((data: any) => {
              this.platformData = data;
            });
          this._snackBar.open('Error! LMS settings not updated.', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
        }
      );
  }

  newLMS() { 
    const dialogRef = this._dialog.open(NewLmsComponent, {
      width: '600px',
      data: {
        name: null,
        company_id: this.company_id,
        onChange: (params: any = null) => {
          this.getLMS();
        },
      },
    });
  }

  uploadFile(lms: any) {
    const dialogRef = this._dialog.open(LmsFileUploadComponent, {
      width: '600px',
      data: {
        name: null,
        type_of_work: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.fileLMSID = lms.id;
        this.fileTYPE = result.data.type_of_work;
        this.fileNAME = result.data.name;
        this.fileUpload.nativeElement.click();
      }
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      //this._platform.LMSFileUploadService(file, `lms/files/${this.fileLMSID}/`,'post').subscribe(
      //  (data:any) => {
      //  },
      //  (error:any) => {
      //  }
      //)
    }
  }

  onColourChange(lms: any) {
    if (
      this.platformData.primary_colour.length === 7 &&
      this.platformData.accent_colour.length === 7 &&
      this.platformData.warn_colour.length === 7
    ) {
      this._platform
        .updateLMS(
          lms.primary_colour,
          lms.accent_colour,
          lms.warn_colour,
          lms.domain,
          lms.hosting_model,
          lms.status,
          lms.plan_expiry,
          lms.name,
          parseInt(lms.id)
        )
        .subscribe(
          (data: any) => {
            this._snackBar.open('Platform settings updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._platform
              .getLMS(parseInt(this.company_id))
              .subscribe((data: any) => {
                this.platformData = data;
              });
            this._snackBar.open(
              'Error platform settings not updated.',
              'Dismiss',
              {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              }
            );
          }
        );
    }
  }

  deleteLms(lms: any) {
    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: lms.id,
        title: 'Delete LMS',
        delete: 'LMS',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        let payload = {
          isHidden: true,
        };
        this._platform.deleteLMS(parseInt(lms.id)).subscribe(
          (data: any) => {
            this.platformData = this.platformData.filter(
              (x: any) => parseInt(x.id) !== parseInt(lms.id)
            );
            this._snackBar.open('LMS deleted.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._snackBar.open('Error! LMS not deleted.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          }
        );
      }
    });
  }
}
