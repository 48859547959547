import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-lms-file-upload',
  templateUrl: './lms-file-upload.component.html',
  styleUrls: ['./lms-file-upload.component.scss']
})
export class LmsFileUploadComponent implements OnInit {

  public contentTypes: any[] = [{'name': "Artwork (inc logo)", 'slug': 'artwork'},{'name': "Content (inc scorm)", 'slug': 'content'}];

  constructor(
    public _dialogRef: MatDialogRef<LmsFileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

}
