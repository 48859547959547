type EnumValue<T extends keyof never> = { [P in T]: P }[T];

// tslint:disable-next-line: no-namespace
export namespace StorageKey {
  export function fromString(key: string): StorageKey {
    if (Object.values(StorageKey).find((value) => value === key)) {
      return key as StorageKey;
    } else {
      throw new Error(`Storage key '${key}' does not exist`);
    }
  }
}

export enum StorageKey {
  companyId = 'company_id',
  appId = 'app_id',
  groupId = 'group_id',
  userDetail = 'userDetails',
}
