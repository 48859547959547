import { Inject, Injectable } from '@angular/core';

import { AbstractStorage } from './abstract-storage';
import { MemoryStorageService } from './memory-storage.service';
import { storageAvailable } from './storage.util';
import { StorageType } from './storage-type.enum';
import { WINDOW } from 'src/app/core/providers/window.provider';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends AbstractStorage {
  constructor(
    @Inject(WINDOW) public window: Window,
  ) {
    super(storageAvailable(StorageType.Local) ? window.localStorage : new MemoryStorageService());
  }
}
