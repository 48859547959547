import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GroupsService } from 'src/app/_services/groups.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmPopupModalComponent } from '../confirm-popup-modal/confirm-popup-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/_services/user.service';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-group-manager-modal',
  templateUrl: './group-manager-modal.component.html',
  styleUrls: ['./group-manager-modal.component.scss'],
})
export class GroupManagerModalComponent implements OnInit {
  public displayedColumns = ["name", "action"];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.data.selectedUsers
  );
  public selection = new SelectionModel<any>(true, []);

  public groupUser: any[] = [];
  public groupUserIds: any[] = [];
  loading = false;
  isError: any = false;
  isLoading = false;
  companyManager: any[] = [];
  companyUser: any[] = [];
  users: any[] = [];
  myControl = new FormControl();
  optionKey = "first_name";

  constructor(
    private dialog: MatDialog,
    private groupService: GroupsService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    public dialogRef: MatDialogRef<GroupManagerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    if (this.data?.id) {
      this.displayedColumns = ['select', 'name', 'action'];
      this.getGroupUser();
    }
    this.loading = true;
    this.getCompanyUser('');
  }

  ngOnInit(): void {
    this.companyManager = this.data.users;
  }

  createGroup() {
    if (this.data.group_name && this.data.manager?.id) {
      let newGroup: any = {
        group_name: this.data?.group_name,
        company: this.data.companyId,
        manager: this.data?.manager.id,
      };
      if (this.data?.newUser.length) {
        newGroup.users = this.data?.newUser;
      }

      this.groupService.createGroup(newGroup).subscribe(
        (data: any) => {
          this.triggerChange({ action: "addGroup" });
          
          this.snackBar.open("Group added successfully.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            // duration: 2000,
            panelClass: ['app-notification-success'],
          });
          
          this.isLoading = false;
          this.dialogRef.close();
        },
        (error: any) => {
          this.isLoading = false;
          this.snackBar.open(error?.error?.detail?.group_name[0], "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: 'app-notification-error',
          });
        }
      );
    } else {
      this.isError = true;
    }
  }

  getGroupUser() {
    this.loading = true;
    const params = {
      group_ids: [this.data.id],
      company_id: this.data.companyId,
      page_size: 99999,
      group_members: true,
    };
    this.groupService.getGroupUser(params).subscribe(
      (data: any) => {
        this.groupUser = data.detail.result;
        this.groupUser.map((user) => {
          if (user.id) {
            this.groupUserIds.push(user.id);
          }
        });
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  removeSingleUser(element: any) {
    let userIds = [];
    userIds.push(element.id);
    let checkUser = this.data.newUser.findIndex(
      (obj: any) => obj == element.id
    );
    if (checkUser == -1) {
      this.deleteUserGroup(userIds);
    } else {
      this.groupUser = this.groupUser.filter((obj) => {
        return obj.id !== element.id;
      });
      this.data.newUser = this.data.newUser.filter((obj: any) => {
        return obj !== element.id;
      });
      this.groupUserIds = this.groupUserIds.filter((obj: any) => {
        return obj !== element.id;
      });
    }
  }

  removeMultiUser() {
    let userIds: any[] = [];
    this.selection.selected.map((user) => {
      let checkUser = this.data.newUser.findIndex((obj: any) => obj == user.id);
      if (checkUser == -1) {
        userIds.push(user.id);
      } else {
        this.groupUser = this.groupUser.filter((obj) => {
          return obj.id !== user.id;
        });
        this.data.newUser = this.data.newUser.filter((obj: any) => {
          return obj !== user.id;
        });
        this.groupUserIds = this.groupUserIds.filter((obj: any) => {
          return obj !== user.id;
        });
      }
    });
    if (userIds.length) {
      this.deleteUserGroup(userIds);
    }
  }

  deleteUserGroup(deleteUser: any) {
    const dialogRef = this.dialog.open(ConfirmPopupModalComponent, {
      width: "400px",
      data: {
        id: this.data.id,
        title: "Delete User from Group",
        delete: "user",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this.isLoading = true;
        this.groupService
          .deleteGroup(parseInt(this.data.id), { users: deleteUser })
          .subscribe(
            (data: any) => {
              let newGroupList: any[] = [];
              this.groupUser.map((group) => {
                if (!deleteUser.includes(group.id)) {
                  newGroupList.push(group);
                }
              });
              this.groupUser = newGroupList;
              this.selection.clear();
              this.snackBar.open("Group delete successfully.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
                panelClass: ['app-notification-success'],
              });
              this.isLoading = false;
              this.triggerChange({ action: "deleteUser" });
            },
            (error: any) => {
              this.isLoading = false;
              let errorMessage = error?.error?.message
                ? error?.error?.message
                : error?.error?.detail?.group_name[0];
              if (errorMessage) {
                this.snackBar.open(errorMessage, "Dismiss", {
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: 'app-notification-error',
                });
              }
            }
          );
      }
    });
  }

  /**
   * An helper function that checkes callback function onChange in data and triggers it if found
   */
  private triggerChange(param: any = null): void {
    if (typeof this.data.onChange === "function") {
      this.data.onChange(param);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.groupUser.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.groupUser);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  changeHandler(searchText: string) {
    this.getComapnayManager(searchText);
  }

  getComapnayManager(searchText: string): void {
    let search = {};
    if (searchText) {
      search = {
        search_params: searchText,
      };
    }
    this.groupService
      .getComapnyManager(parseInt(this.data.companyId), search)
      .subscribe(
        (data: any) => {
          this.companyManager = data.detail.result;
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
        }
      );
  }

  getManager(manager: any): void {
    this.data.manager = manager;
  }

  addUser(user: any): void {
    let checkUser = this.data.newUser.findIndex((obj: any) => obj == user.id);

    if (checkUser == -1) {
      this.groupUser = this.groupUser.concat(user);
      this.data.newUser.push(user.id);
      this.groupUserIds.push(user.id);
      // this.companyUser = this.companyUser.filter(
      //   ({ id: id1 }) => !this.groupUser.some(({ id: id2 }) => id2 === id1)
      //   );
    }
  }

  updateGroup(): void {
    if (this.data.group_name && this.data.manager?.id) {
      this.isLoading = true;
      let groupData: any = {
        company: this.data.companyId,
      };
      if (this.data.newUser.length) {
        groupData.users = this.data?.newUser;
      }
      if (this.data.groupDetail.group_name !== this.data.group_name) {
        groupData.group_name = this.data?.group_name;
      }
      if (this.data.groupDetail.manager.id !== this.data.manager) {
        groupData.manager = this.data?.manager?.id;
      }

      this.groupService
        .updateGroup(parseInt(this.data?.id), groupData)
        .subscribe(
          (data: any) => {
            this.data.newUser.length = 0;
            if (data?.detail) {
              this.data.groupDetail.group_name = data?.detail?.group_name;
              this.data.groupDetail.manager.id = data?.detail?.manager;
            }
            this.snackBar.open("Group updated successfully.", "Dismiss", {
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 2000,
              panelClass: ['app-notification-success'],
            });
            this.isLoading = false;
            
            this.triggerChange({ action: "updateGroup" });
            this.dialogRef.close();
          },
          (error: any) => {
            this.isLoading = false;
            let errorMessage = error?.error?.message
              ? error?.error?.message
              : error?.error?.detail?.group_name[0];
            if (errorMessage) {
              this.snackBar.open(errorMessage, "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: 'app-notification-error',
              });
            }
          }
        );
    } else {
      this.isError = true;
    }
  }

  removeUser(user: any): void {
    let checkUser = this.data.newUser.findIndex((obj: any) => obj == user.id);

    if (checkUser !== -1) {
      this.groupUser = this.groupUser.filter((obj: any) => {
        return obj.id !== user.id;
      });
      this.data.newUser = this.data.newUser.filter((obj: any) => {
        return obj !== user.id;
      });
      this.groupUserIds = this.groupUserIds.filter((obj: any) => {
        return obj !== user.id;
      });
    }
  }

  changeHandlerUser(searchText: string) {
    this.getCompanyUser(searchText);
  }

  getCompanyUser(searchText: string): void {
    const params: any = {
      page_size: 25,
      group_members: false,
      company_id: this.data.companyId,
    };
    if (searchText) {
      params.search_params = searchText;
    }
    if (!searchText && this.data.id) {
      params.group_ids = [this.data.id];
    }

    this.groupService.getGroupUser(params).subscribe(
      (data: any) => {
        this.companyUser = data.detail.result;
        this.users = data.detail.result;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}
