<mat-toolbar
  color="primary"
  class="page-footer p-t-20 p-b-20 p-l-30 p-r-30"
  style="height: 230px"
>
  <div fxLayout="row wrap" style="width: 100%">
    <div fxFlex class="align-self-center">
		<img
        class="footer-image"
        [src]="
          theme?.logos?.primary || 'assets/images/xelerate-footer-logo.png'
        "
        alt="homepage"
      />
    </div>
    <div fxFlex fxLayout="row wrap" fxLayoutAlign="flex-end space-between">
      <ul>
        <li class="title contact-item">{{ "footer.contact" | translate }}</li>
        <li class="contact-item">
          <a href="mailto:hello@xeleratelearning.com"
            ><i class="fa fa-at"></i>: hello@xeleratelearning.com</a
          >
        </li>
        <li>
          <a href="tel:+442476525550"
            ><i class="fa fa-phone"></i>: +44 (0) 2476 525 550</a
          >
        </li>
      </ul>
    </div>
    <div
      fxFlex="100"
      fxLayout="row wrap"
      fxLayoutAlign="flex-start space-between"
      class="legals"
    >
      <div fxFlex class="p-t-20">
        &copy; {{ today | date : "YYYY" }} Xelerate Learning
      </div>
      <div fxFlex class="p-t-20">
        <a href="#">{{ "footer.privacy_policy" | translate }}</a>
      </div>
      <div fxFlex class="p-t-20">
        <a href="#">{{ "footer.apps_privacy_policy" | translate }}</a>
      </div>
    </div>
  </div>
</mat-toolbar>
