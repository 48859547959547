import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "src/app/_services/user.service";

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {
  isLoading = false;

  constructor(
    public _dialogRef: MatDialogRef<AddressModalComponent>,
    private _snackBar: MatSnackBar,
    private _user: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

   /**
   * An helper function that checkes callback function onChange in data and triggers it if found
   */
   private triggerChange(param: any = null): void {
    if (typeof this.data.onChange === "function") {
      this.data.onChange(param);
    }
  }

  editContact(): void {
    if (this.data) {
      this.isLoading = true;
      if (
        !this.data.primary &&
        this.data.companyData.primary_contact &&
        this.data.companyData.primary_contact.id === this.data.id
      ) {
        this.data.companyData.primary_contact = null;
      }

      if (this.data.primary) {
        this.data.companyData.primary_contact = this.data.contact;
      }

      if (
        !this.data.billing &&
        this.data.companyData.billing_contact &&
        this.data.companyData.billing_contact.id === this.data.id
      ) {
        this.data.companyData.billing_contact = null;
      }

      if (this.data.billing) {
        this.data.companyData.billing_contact = this.data.contact;
      }

      if (
        !this.data.technical &&
        this.data.companyData.technical_contact &&
        this.data.companyData.technical_contact.id === this.data.id
      ) {
        this.data.companyData.technical_contact = null;
      }

      if (this.data.technical) {
        this.data.companyData.technical_contact = this.data.contact;
      }
      if (this.data.companyData) {
        this._user
          .modifyContact(this.data, this.data.companyData.id, false, false)
          .subscribe(
            (data: any) => {
              this._snackBar.open("Contact updated.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
              this.isLoading = false;
              this.triggerChange({ action: "addContact", data: this.data });
              this._dialogRef.close();
            },
            (error: any) => {
              let errorMessage =
                error.error.message || "Error contact not updated.";
              this._snackBar.open(errorMessage, "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
              this.isLoading = false;
            }
          );
      }
    }
  }

  addContact(): void {
    if (this.data) {
      let payloadData = JSON.parse(JSON.stringify(this.data));
      this.isLoading = true;
      delete payloadData.onChange;
      let copiedData = JSON.parse(JSON.stringify(payloadData));
      delete copiedData.companyData;
      if (payloadData.primary) {
        payloadData.companyData.primary_contact = copiedData;
      }

      if (payloadData.billing) {
        payloadData.companyData.billing_contact = copiedData;
      }

      if (payloadData.technical) {
        payloadData.companyData.technical_contact = copiedData;
      }

      if (payloadData.companyData) {
        this._user
          .modifyContact(payloadData, payloadData.companyData.id, true, false)
          .subscribe(
            (data: any) => {
              this._snackBar.open("Contact created.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
              this.isLoading = false;
              this.triggerChange({ action: "addContact", data: copiedData });
              this._dialogRef.close();
            },
            (error: any) => {
              this._snackBar.open("Error contact not created.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
              this.isLoading = false;
            }
          );
      }
    }
  }
  
}
