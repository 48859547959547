import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { HeadsetService } from '../../_services/headset.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CreateSupplierOrderComponent } from '../../shared/create-supplier-order/create-supplier-order.component';
import { AuthenticationFinalResponse } from '../../_models/user_authentication';
import { HeadsetModalComponent } from '../headset-modal/headset-modal.component';
import { ConfirmPopupModalComponent } from '../../shared/confirm-popup-modal/confirm-popup-modal.component';

@Component({
  selector: 'app-headset-manager',
  templateUrl: './headset-manager.component.html',
  styleUrls: ['./headset-manager.component.scss'],
})
export class HeadsetManagerComponent implements OnInit {
  public headsetsAvailable!: any[];
  public headsetsPurchased!: any[];

  public displayedColumns = [
    'name',
    'date',
    'quantity_purchased',
    'quantity_used',
    'quantity_remaining',
    'actions',
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.headsetsPurchased
  );

  @Input('company') company_id: string = '1';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  loading = false;
  isSearchFormSubmitted = false;
  searchAssignedHeadsets: any = "";

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _headset: HeadsetService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.company_id) {
      this.getAssignedHeadsets();
      this.getHeadsets();
    }
  }

  getHeadsets(): void {
    this._headset
      .getHeadsets(parseInt(this.company_id))
      .subscribe((data: any) => {
        this.headsetsAvailable = data.detail.result;
      });
  }

  getAssignedHeadsets(): void {
    this.loading = true;
    this._headset
      .getAssignedHeadsets(
        parseInt(this.company_id),
        this.pageSize,
        this.offset,
        this.searchAssignedHeadsets
      )
      .subscribe(
        (data: any) => {
          this.headsetsPurchased = data.detail.result;
          this.dataSource.data = this.headsetsPurchased;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = data.detail.count;
          });
          this.loading = false;
        },
        (error: any) => {
          this._snackBar.open('Error while Loading Data.', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
          });
          this.loading = false;
        }
      );
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAssignedHeadsets();
  }

  editHeadset(element: any) {
    const dialogRef = this._dialog.open(HeadsetModalComponent, {
      width: '600px',
      data: {
        id: element.id,
        headset: {
          id: element.id,
          headset_name: {
            name: element.headset_name.name,
            id: element.headset_name.id,
          },
          quantity_purchased: element.quantity_purchased,
        },
        headsets_available: this.headsetsAvailable,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        const min_quantity = this.headsetsAvailable.filter(
          (x: any) => x.id === result.data.headset.headset_name.id
        )[0].min_quantity;
        var min_q_d = result.data.headset.quantity_purchased / min_quantity;
        var quantity_purchased = Math.ceil(min_q_d) * min_quantity;

        let payload = {
          quantity_purchased: quantity_purchased,
        };
        this._headset.updateHeadset(element.id, payload).subscribe(
          (data: any) => {
            this.getAssignedHeadsets();
            this._snackBar.open('Order updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._snackBar.open('Error order not updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          }
        );
      }
    });
  }

  addHeadset() {
    const dialogRef = this._dialog.open(HeadsetModalComponent, {
      width: '600px',
      data: {
        id: null,
        headset: {
          id: null,
          headset_name: { name: null, id: null },
          quantity_purchased: null,
        },
        headsets_available: this.headsetsAvailable,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        const min_quantity = this.headsetsAvailable.filter(
          (x: any) => x.id === result.data.headset.headset_name.id
        )[0].min_quantity;
        var min_q_d = result.data.headset.quantity_purchased / min_quantity;
        var quantity_purchased = Math.ceil(min_q_d) * min_quantity;

        this._headset
          .createHeadset(
            parseInt(this.company_id),
            result.data.headset.headset_name.id,
            quantity_purchased
          )
          .subscribe(
            (data: any) => {
              this.getAssignedHeadsets();
              this._snackBar.open('Order added.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open('Error order not added.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            }
          );
      }
    });
  }

  deleteHeadset(element: any) {
    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: element.id,
        title: 'Delete Headset',
        delete: 'Headset',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        let payload = {
          isHidden: true,
        };

        this._headset.deleteHeadset(element.id).subscribe(
          (data: any) => {
            this._snackBar.open('Headset deleted.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
            this.dataSource.data = this.dataSource.data.filter(item => item !== element);
          },
          (error: any) => {
            this._snackBar.open('Error! Headset not deleted.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          }
        );
      }
    });
  }

  suspendHeadset(element: any, status: boolean) {
    let title = status ? 'Suspend Headset' : 'Activate Headset';
    let successMsg = status ? 'Headset activated.' : 'Headset suspended.';
    let errMsg = status ? 'Error! Headset not activated.' : 'Error! Headset not suspended.';

    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: element.id,
        title: title,
        delete: 'Headset',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        let payload = {
          isHidden: true,
        };
        let h_payload = {
          status: status,
        };
        this._headset.updateHeadset(element.id, h_payload).subscribe(
          (data: any) => {
            this._snackBar.open(successMsg, 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._snackBar.open(errMsg, 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          }
        );
      }
    });
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchAssignedHeadsets = "";
      return;
    }

    this.searchAssignedHeadsets = "";
     this.getAssignedHeadsets()
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchAssignedHeadsets = this.searchAssignedHeadsets.trim()
     this.getAssignedHeadsets()
  }
}
