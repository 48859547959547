<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
     
      <div fxFlex="100" >
        <div fxFlex="80" style="text-align: left">
          <h3>Create Order</h3>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label><ng-container *ngIf="data.client">Client</ng-container><ng-container *ngIf="!data.client">Supplier</ng-container></mat-label>
            <mat-select [(ngModel)]="data.order.supplier">
    			<mat-option *ngFor="let t of data.suppliers" [value]="t">{{ t }}</mat-option>
  			</mat-select>
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Description</mat-label>
            <input matInput type="email" [(ngModel)]="data.order.line_description">
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Amount</mat-label>
            <input matInput type="number" [(ngModel)]="data.order.amount">
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Payment Method</mat-label>
            <mat-select [(ngModel)]="data.order.payment_method">
    			<mat-option *ngFor="let t of data.payment_methods" [value]="t">{{ t }}</mat-option>
  			</mat-select>
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <button mat-raised-button color="primary" style="width:100%" (click)="closeDialog()"><mat-icon>add_shopping_cart</mat-icon>Create Order</button>
      </div>
  </div>
</mat-dialog-content>
