<mat-dialog-content class="mat-typography">
	<div fxLayout="row wrap">
	  <div fxFlex="100">
		<div fxFlex="80" style="text-align: left">
		  <h3>Issue Headset</h3>
		</div>
		<div fxFlex="20" style="text-align: right">
		  <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
		</div>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <mat-label>Headset Type</mat-label>
		  <mat-select [(ngModel)]="data.issue.headset_id">
			<mat-option *ngFor="let t of data.headsets" [value]="t.id">{{
			  t.headset_name.name
			}}</mat-option>
		  </mat-select>
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <mat-label>Saved Address</mat-label>
		  <mat-select
			[ngModel]="data.issue.address_id"
			(ngModelChange)="onChange($event)"
			[disabled]="data.user_addresses.length === 0"
		  >
			<mat-option *ngFor="let t of data.user_addresses" [value]="t.id"
			  >{{ t.address_1 }} {{ t.postcode }}</mat-option
			>
		  </mat-select>
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <mat-label>Name</mat-label>
		  <input matInput type="text" [(ngModel)]="data.issue.name" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <mat-label>Address 1</mat-label>
		  <input matInput type="text" [(ngModel)]="data.issue.address_1" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <input matInput type="text" [(ngModel)]="data.issue.address_2" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <input matInput type="text" [(ngModel)]="data.issue.address_3" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <input matInput type="text" [(ngModel)]="data.issue.address_4" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <mat-label>Postcode</mat-label>
		  <input matInput type="text" [(ngModel)]="data.issue.postcode" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<mat-form-field>
		  <mat-label>Country</mat-label>
		  <input matInput type="text" [(ngModel)]="data.issue.country" />
		</mat-form-field>
	  </div>
	  <div fxFlex="100">
		<button
		  mat-raised-button
		  color="primary"
		  (click)="closeDialog()"
		  style="width: 100%; margin-top: 16px"
		  [disabled]="!data.issue.address_1 && !data.issue.headset_id"
		>
		  <mat-icon>save</mat-icon>Issue Headset
		</button>
	  </div>
	</div>
  </mat-dialog-content>
  