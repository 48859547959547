<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
      <div fxFlex="100">
        <div fxFlex="80" style="text-align: left">
          <h3 *ngIf="data.id">Edit Sub Domain</h3>
         <h3 *ngIf="!data.id">Create Sub Domain</h3>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Sub Domain</mat-label>
            <input matInput type="email" [(ngModel)]="data.subdomain" [readonly]="data.id">
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Domain</mat-label>
            <mat-select [(ngModel)]="data.domain" [disabled]="data.id">
              <mat-option *ngFor="let d of domains" [value]="d">
                 {{ d }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Server</mat-label>
            <input matInput type="email" [(ngModel)]="data.server" readonly>
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Description</mat-label>
            <textarea matInput type="email" [(ngModel)]="data.description"></textarea>
          </mat-form-field>
      </div>
      <div fxFlex="100">
      	<mat-slide-toggle [(ngModel)]="data.restricted">Restricted</mat-slide-toggle>
      </div>
      <div fxFlex="100">
      	<mat-slide-toggle [(ngModel)]="data.custom">Dedicated</mat-slide-toggle>
      </div>
       <div fxFlex="100" class="m-t-10">
        <button  mat-raised-button color="primary" style="width: 100%;" *ngIf="data.id" (click)="closeDialog()"><mat-icon>update</mat-icon> Update Sub Domain</button>
        <button  mat-raised-button color="primary" style="width: 100%;" *ngIf="!data.id" (click)="closeDialog()" [disabled]="checkValues()"><mat-icon>person_add</mat-icon> Add Sub Domain</button>
      </div>

  </div>
</mat-dialog-content>