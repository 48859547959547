import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'formatSeconds',
})
export class FormatSecondsPipe implements PipeTransform {
  transform(value: any, format?: any, args?: any): any {
    if (format === 'long'){
      return value ? this.formatSecondstoHour(value) : 0;
    }else {
      return value ? this.formatSeconds(value) : 0;
    }
  }

  private formatSeconds = (s: number): any =>
    new Date(s * 1000).toISOString().slice(11, 19);

  private formatSecondstoHour = (s: number): any => {
    const hours = Math.floor(s / 3600);
    const remainingSeconds = s % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return `${hours}h ${minutes}m`;
  }
}
