import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'secure'
})

export class SecurePipe implements PipeTransform {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    async transform(url:string): Promise<string> {
        const imageBlob = await this.http.get(url, {responseType: 'blob'}).toPromise();
        const reader = new FileReader();
        console.log('here');
        return new Promise((resolve, reject) => {
      		reader.onloadend = () => resolve(reader.result as string);
      		reader.readAsDataURL(imageBlob);
    	});

    }

}