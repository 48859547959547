import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isArray, isBoolean, isDate } from 'lodash';
/**
 * This pipe is used to parse the value field and format it as required.
 *
 * @param {string} any
 * because learning Objective data is coming as string all the time.
 * So it need to be parsed before render. For example -
 * A Boolean value is coming as wrapped with double quotes, aarray is coming wrapped with double quotes
 * @export
 * @class ParseValuePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'parseValue',
})
export class ParseValuePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value: any, type?: string): any {
    return this.getValue(value, type);
  }

  private getValue(text: any, type?: string): any {
    let value = text;

    if (isDate(value)) {
      return this.datePipe.transform(value);
    } else if (isBoolean(value)) {
      return value.toString()?.toLowerCase() === 'true' ? 'TRUE' : 'FALSE';
    } else if (isArray(value)) {
      return 'LIST';
    } /*
    * @todo in future there can a URL of image.
    else if (isImage(value)) {
      return value.join(', ');
    }*/ else {
      return value;
    }
  }
}
