import { StorageType } from './storage-type.enum';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */
export function storageAvailable(type: StorageType): boolean {
  let storage;

  try {
    const storageTest = '__storage_test__';

    storage = window[type];
    storage.setItem(storageTest, storageTest);
    storage.removeItem(storageTest);

    return true;
  } catch (e) {
    return e instanceof DOMException
      && (
        // everything except Firefox
        // eslint-disable-next-line no-magic-numbers
        e.code === 22
        // Firefox
        // eslint-disable-next-line no-magic-numbers
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )
      // acknowledge QuotaExceededError only if there's something already stored
      && storage
      && storage.length !== 0;
  }
}
