import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MasterLicenseService } from '../../_services/masterlicense.service';
import { MasterLicenseModalComponent } from '../master-license-modal/master-license-modal.component';
import { ActiveMasterLicensesComponent } from '../active-master-licenses/active-master-licenses.component';
import { ExpiredMasterLicensesComponent } from '../expired-master-licenses/expired-master-licenses.component'

@Component({
  selector: 'app-master-licenses',
  templateUrl: './master-licenses.component.html',
  styleUrls: ['./master-licenses.component.scss']
})
export class MasterLicensesComponent implements OnInit {
  @ViewChild(ActiveMasterLicensesComponent) ActiveMasterLicensesComponent: ActiveMasterLicensesComponent;
  @ViewChild(ExpiredMasterLicensesComponent) ExpiredMasterLicensesComponent: ExpiredMasterLicensesComponent;
  @Input('company') company_id: string = '1';
  public apps_list: any[];
  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar, private _masterLicense: MasterLicenseService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.getApps();
  }

  getApps(): void {
    const savedAppsList = localStorage.getItem('apps_list');
    if (savedAppsList) {
      this.apps_list = JSON.parse(savedAppsList);
    }
  }

  addLicense() {
    const dialogRef = this._dialog.open(MasterLicenseModalComponent, {
      width: '600px',
      data: {
        module: { name: null, id: null },
        expiry: null,
        maximum_issue: null,
        payment_method: null,
        id: null,
        modules_available: this.apps_list,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this._masterLicense
          .createMasterLicense(
            result.data.module.id,
            result.data.expiry,
            result.data.maximum_issue,
            result.data.payment_method,
            this.company_id
          )
          .subscribe(
            (data: any) => {
              this._snackBar.open('License added.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
              this.refreshChildComponent();
            },
            (error: any) => {
              this._snackBar.open('Error license not added.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            }
          );
      }
    });
  }

  refreshChildComponent(): void {
    if (this.ActiveMasterLicensesComponent) {
      this.ActiveMasterLicensesComponent.getMasterLicense();
    }

    if (this.ExpiredMasterLicensesComponent) {
      this.ExpiredMasterLicensesComponent.getMasterLicense();
    }
  }
} 
