import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuItems } from './menu-items/menu-items';
import { HorizontalMenuItems } from './menu-items/horizontal-menu-items';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { CodeEntryComponent } from './code-entry/code-entry.component';
import { SecurePipe } from '../_pipes/secure.pipe';
import { SecuredImageComponent } from './secured-image/secured-image.component';
import { NotesComponent } from './notes/notes.component';
import { DemoMaterialModule } from '../demo-material-module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { DocumentModalComponent } from './document-modal/document-modal.component';
import { CreateSupplierOrderComponent } from './create-supplier-order/create-supplier-order.component';
import { MasterLicensesComponent } from './master-licenses/master-licenses.component';
import { MasterLicenseModalComponent } from './master-license-modal/master-license-modal.component';
import { UserManagerComponent } from './user-manager/user-manager.component';
import { HeadsetManagerComponent } from './headset-manager/headset-manager.component';
import { HeadsetModalComponent } from './headset-modal/headset-modal.component';
import { HeadsetIssueModalComponent } from './headset-issue-modal/headset-issue-modal.component';
import { PapaParseModule } from 'ngx-papaparse';
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';
import { HeadsetIssueManagerComponent } from './headset-issue-manager/headset-issue-manager.component';
import { HeadsetCarrierModalComponent } from './headset-carrier-modal/headset-carrier-modal.component';
import { PlatformSettingsComponent } from './platform-settings/platform-settings.component';
import { NgxColorsModule } from 'ngx-colors';
import { SubdomainsComponent } from './subdomains/subdomains.component';
import { SubdomainModalComponent } from './subdomain-modal/subdomain-modal.component';
import { LicenseManagerComponent } from './license-manager/license-manager.component';
import { LicenseBundleModalComponent } from './license-bundle-modal/license-bundle-modal.component';
import { LmsDetailComponent } from './lms-detail/lms-detail.component';
import { LmsFileUploadComponent } from './lms-file-upload/lms-file-upload.component';
import { NewLmsComponent } from './new-lms/new-lms.component';
import { NewCompanyModalComponent } from './new-company-modal/new-company-modal.component';
import { UploadAssetBundleModalComponent } from './upload-asset-bundle-modal/upload-asset-bundle-modal.component';
import { CreateAssetBundleComponent } from './create-asset-bundle/create-asset-bundle.component';
import { FormatSecondsPipe } from '../_pipes/format-seconds.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ParseValuePipe } from '../_pipes/parse-value.pipe';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';
import { BooleanViewComponent } from './boolean-view/boolean-view.component';
import { RadialBarComponent } from './radial-bar/radial-bar.component';
import { RenderListValueComponent } from './render-list-value/render-list-value.component';
import { GroupManagerComponent } from './group-manager/group-manager.component';
import { GroupManagerModalComponent } from './group-manager-modal/group-manager-modal.component';
import { ConfirmPopupModalComponent } from './confirm-popup-modal/confirm-popup-modal.component';
import { AssignMultiUserGroupModalComponent } from './assign-multi-user-group-modal/assign-multi-user-group-modal.component';
import { MoveUserAnotherGroupComponent } from './move-user-another-group/move-user-another-group.component';
import { MergeGroupModalComponent } from './merge-group-modal/merge-group-modal.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { LoaderComponent } from './loader/loader.component';
import { UserCardComponent } from './user-card/user-card.component';
import { DocumentModalUpdateComponent } from './document-modal-update/document-modal-update.component';
import { ActiveMasterLicensesComponent } from './active-master-licenses/active-master-licenses.component';
import { ExpiredMasterLicensesComponent } from './expired-master-licenses/expired-master-licenses.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CodeEntryComponent,
    SecurePipe,
    SecuredImageComponent,
    NotesComponent,
    AddressModalComponent,
    DocumentModalComponent,
    CreateSupplierOrderComponent,
    MasterLicensesComponent,
    MasterLicenseModalComponent,
    UserManagerComponent,
    HeadsetManagerComponent,
    HeadsetModalComponent,
    HeadsetIssueModalComponent,
    AddUserModalComponent,
    HeadsetIssueManagerComponent,
    HeadsetCarrierModalComponent,
    PlatformSettingsComponent,
    SubdomainsComponent,
    SubdomainModalComponent,
    LicenseManagerComponent,
    LicenseBundleModalComponent,
    LmsDetailComponent,
    LmsFileUploadComponent,
    NewLmsComponent,
    NewCompanyModalComponent,
    UploadAssetBundleModalComponent,
    CreateAssetBundleComponent,
    FormatSecondsPipe,
    ProgressBarComponent,
    ParseValuePipe,
    SafeHtmlPipe,
    BooleanViewComponent,
    RadialBarComponent,
    RenderListValueComponent,
    GroupManagerComponent,
    GroupManagerModalComponent,
    ConfirmPopupModalComponent,
    AssignMultiUserGroupModalComponent,
    MoveUserAnotherGroupComponent,
    MergeGroupModalComponent,
    AutocompleteComponent,
    LoaderComponent,
    UserCardComponent,
    DocumentModalUpdateComponent,
    ActiveMasterLicensesComponent,
    ExpiredMasterLicensesComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatInputModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    NgxMatIntlTelInputModule,
    PapaParseModule,
    NgxColorsModule,
    NgApexchartsModule
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CodeEntryComponent,
    SecurePipe,
    SecuredImageComponent,
    NotesComponent,
    AddressModalComponent,
    DocumentModalComponent,
    CreateSupplierOrderComponent,
    MasterLicensesComponent,
    UserManagerComponent,
    GroupManagerComponent,
    HeadsetManagerComponent,
    HeadsetIssueManagerComponent,
    PlatformSettingsComponent,
    LicenseManagerComponent,
    LmsDetailComponent,
    NewCompanyModalComponent,
    UploadAssetBundleModalComponent,
    CreateAssetBundleComponent,
    FormatSecondsPipe,
    ProgressBarComponent,
    ParseValuePipe,
    SafeHtmlPipe,
    BooleanViewComponent,
    RadialBarComponent,
    RenderListValueComponent,
    LoaderComponent,
    UserCardComponent
  ],
  providers: [MenuItems, HorizontalMenuItems, FormatSecondsPipe, SafeHtmlPipe]
})
export class SharedModule { }
