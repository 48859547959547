<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3>Add PDF presentation</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Name</mat-label>
          <input matInput type="email" [(ngModel)]="dataDoc.name" maxlength="25">
        </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea
          matInput
          type="email"
          [(ngModel)]="dataDoc.description"
          rows="3"
        ></textarea>
      </mat-form-field>
    </div>

    <div fxFlex="100" style="margin-bottom: 10px;">
      <mat-checkbox [(ngModel)]="dataDoc.default_issue"
        >Company Level</mat-checkbox
      >
    </div>

    <input
      type="file"
      accept="application/pdf"
      #fileUpload
      style="display: none"
      (change)="onChange($event)"
    />
    <div fxFlex="100">
      <button
        mat-raised-button
        color="primary"
        (click)="fileUpload.click()"
        style="width: 100%"
        [disabled]="!dataDoc.name"
      >
        <mat-icon>upload</mat-icon>Upload PDF
      </button>
    </div>
  </div>
</mat-dialog-content>
