<div fxLayout="row wrap">
  <div fxFlex="100" style="display: flex; justify-content: end">
    <div>
      <button mat-raised-button color="primary" (click)="addLicense()">
        <mat-icon>add_shopping_cart</mat-icon> Add License
      </button>
    </div>
  </div>
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <app-active-master-licenses [company]="company_id"></app-active-master-licenses>
    <app-expired-master-licenses [company]="company_id"></app-expired-master-licenses>
  </div>
</div>
