import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  AuthenticationFirstResponse,
  AuthenticationFinalResponse,
  User,
  CompanyUser,
  MasterLicense,
} from '../_models/user_authentication';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppCustomisation, Branding, LogoUrls } from '../_models/branding';

interface PlatFormSettings {
  app_customisation: AppCustomisation;
  logo_read_url: LogoUrls;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private brandingSettings: Branding;
  constructor(private http: HttpClient) { }

  setBranding(settings: PlatFormSettings): void {
    this.brandingSettings = new Branding().deserialize(settings);
    const favicon = settings?.logo_read_url?.secondary;
    if (favicon) {
      this.setFaviconIcon(favicon);
    }
  }

  private setFaviconIcon(iconURL: string): void {
    const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = iconURL;
  }

  get branding(): Branding {
    return this.brandingSettings;
  }

  updatePlatformColours(
    primary: string,
    accent: string,
    warn: string,
    company_id: number
  ) {
    return this.http
      .put<any>(`${environment.authUrl}/v1/company/platform/${company_id}/`, {
        primary,
        accent,
        warn,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getServers() {
    return this.http.get<any>(`${environment.authUrl}/server/`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error || 'Something went wrong!'))
    );
  }

  getPlatformSettings(id: number | null) {
    if (id) {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/platform/${id}/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/platform/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    }
  }

  getDefaultSettings(params: { domain: string }): Observable<any> {
    return this.http
      .post<any>(
        `${environment.authUrl}/v1/company/default_platform_settings/`,
        params
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getLMS(id: number | null, query: any = null) {
    if (id) {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/lms/${id}/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || 'Something went wrong!'))
        );
    } else {
      return this.http.get<any>(`${environment.authUrl}/v1/company/lms/?query=${query}`).pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
    }
  }

  createLMS(id: number, name: string) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/lms/${id}/`, { name })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateLMS(
    primary_colour: string,
    accent_colour: string,
    warn_colour: string,
    domain: string,
    hosting_model: string,
    status: string,
    plan_expiry: Date,
    name: string,
    id: number
  ) {
    return this.http
      .put<any>(`${environment.authUrl}/v1/company/lms/${id}/`, {
        primary_colour,
        accent_colour,
        warn_colour,
        domain,
        name,
        hosting_model,
        status,
        plan_expiry,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
  deleteLMS(id: number) {
    return this.http
      .delete<any>(`${environment.authUrl}/v1/company/lms/${id}/`,)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
}
