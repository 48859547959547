import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-asset-bundle-modal',
  templateUrl: './upload-asset-bundle-modal.component.html',
  styleUrls: ['./upload-asset-bundle-modal.component.scss']
})
export class UploadAssetBundleModalComponent implements OnInit {

  constructor(
    public _dialogRef: MatDialogRef<UploadAssetBundleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  { 
    console.log(this.data)
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this._dialogRef.close({data: this.data});
  }

}
