import { Deserializable } from './deserializable';

export interface AppCustomisation {
  font?: string;
  font_primary_color?: string;
  font_secondary_color?: string;
  primary_color?: string;
  secondary_color?: string;
  warm_color?: string;
  text_color?: string;
}

export interface LogoUrls {
  primary?: string;
  secondary?: string;
}

export class Branding implements Deserializable {
  appCustomisation: AppCustomisation;
  logos: LogoUrls;
  name: string;

  deserialize(input: any): any {
    const { app_customisation = {}, logo_read_url = {}, name } = input;
    const appCustomisation = {
      ...app_customisation,
    };

    const logos = {
      ...{
        primary: 'assets/images/xelerate-logo.png',
        secondary: 'assets/images/xelerate-logo.png',
      },
      ...logo_read_url,
    };

    Object.assign(this, { appCustomisation, logos, name });

    const primaryColorKey = `--primary-color`;
    document.documentElement.style.setProperty(primaryColorKey, appCustomisation.primary_color || '##ff00c2');
    const secondaryColorKey = `--secondary-color`;
    document.documentElement.style.setProperty(secondaryColorKey, appCustomisation.secondary_color || '#000');
    const warmColorKey = `--warm-color`;
    document.documentElement.style.setProperty(warmColorKey, appCustomisation.warn_color || '#fff');

    const fontPrimaryColorKey = `--font-primary-color`;
    document.documentElement.style.setProperty(fontPrimaryColorKey, appCustomisation.font_primary_color || '#000');

    const fontSecondaryColorKey = `--font--secondary-color`;
    document.documentElement.style.setProperty(fontSecondaryColorKey, appCustomisation.font_secondary_color || '#000');

    const textColorKey = `--text-color`;
    document.documentElement.style.setProperty(textColorKey, appCustomisation.text_color || '#000');

    return this;
  }
}
