import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { NotesService } from '../../_services/notes.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationFinalResponse } from '../../_models/user_authentication';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ConfirmPopupModalComponent } from '../../shared/confirm-popup-modal/confirm-popup-modal.component';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Input('company') company_id: string = '0';
  @Input('application') application_id: string = '0';
  @Input('elearning') elearning_id: string = '0';
  @Input('support') support_id: string = '0';

  public user!: AuthenticationFinalResponse;
  public sidePanelOpened = true;
  public selectedNote: any = { saved: true };
  public searchText = '';
  public clrName = 'warning';
  public colors = [
    { colorName: 'info' },
    { colorName: 'warning' },
    { colorName: 'danger' },
    { colorName: 'success' },
  ];
  public savedNote: boolean[] = [];
  public notes: any[] = [];

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  loading = false;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.notes
  );

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);

  constructor(
    private _user: UserService,
    public _snackBar: MatSnackBar,
    private _notes: NotesService,
    private _dialog: MatDialog
  ) {
    this._user.currentUserDetails.subscribe(
      (data: AuthenticationFinalResponse) => {
        this.user = data;
      }
    );
  }

  ngOnChanges(changes: any) {
    this.fetchNotes();
  }

  fetchNotes() {
    this.loading = true;
    if (this.company_id && this.company_id !== '0') {
      this._notes
        .getNotes('company', this.company_id, this.pageSize, this.offset)
        .subscribe((data: any) => {
          this.notes = data.detail.result;
          for (let note of this.notes) {
            note.saved = true;
          }
          this.selectedNote = this.notes[0];

          this.dataSource.data = this.notes;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = data.detail.count;
          });
          this.loading = false;
        });
    }
    if (this.application_id && this.application_id !== '0') {
      this._notes
        .getNotes('application', this.application_id, this.pageSize, this.offset)
        .subscribe((data: any) => {
          this.notes = data.detail.result;
          for (let note of this.notes) {
            note.saved = true;
          }
          this.selectedNote = this.notes[0];
          this.dataSource.data = this.notes;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = data.detail.count;
          });
          this.loading = false;
        });
    }
    if (this.elearning_id && this.elearning_id !== '0') {
      this._notes
        .getNotes('elearning', this.elearning_id, this.pageSize, this.offset)
        .subscribe((data: any) => {
          this.notes = data.detail.result;
          for (let note of this.notes) {
            note.saved = true;
          }
          this.selectedNote = this.notes[0];
          this.dataSource.data = this.notes;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = data.detail.count;
          });
          this.loading = false;
        });
    }
    if (this.support_id && this.support_id !== '0') {
      this._notes
        .getNotes('support', this.support_id, this.pageSize, this.offset)
        .subscribe((data: any) => {
          this.notes = data.detail.result;
          for (let note of this.notes) {
            note.saved = true;
          }
          this.selectedNote = this.notes[0];
          this.dataSource.data = this.notes;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = data.detail.count;
          });
          this.loading = false;
        });
    }
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.fetchNotes();
  }

  sidePanelControl() {
    this.sidePanelOpened = !this.sidePanelOpened;
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad(): void {
    this.selectedNote = this.notes[0];
  }

  onSelect(note: any): void {
    this.selectedNote = note;
  }

  removenote(note: any): void {
    const index: number = this.notes.indexOf(note);

    if (index !== -1) {
      this.notes.splice(index, 1);
      this.selectedNote = this.notes[0];
    }
  }

  deleteNote(note: any): void {
    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: note.id,
        title: 'Delete Note',
        delete: 'Notes',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        let payload = {
          isHidden: true,
        };

        this._notes.deleteNote(note.id).subscribe(
          (data: any) => {
            this.notes = this.notes.filter((x: any) => x.id !== note.id);

            this._snackBar.open('Note deleted', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (error: any) => {
            this._snackBar.open('Error!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            this.fetchNotes();
          }
        );
      }
    })

  }

  addNoteClick(): void {
    let note = {
      color: this.clrName,
      note: 'Enter your note and click save...',
      added_by: { first_name: this.user.attributes.first_name },
      date_created: new Date(),
      saved: false,
    };
    this.notes.unshift(note);
    this.selectedNote = note;
  }

  saveNotes(note_to_save: string) {
    if (this.company_id && this.company_id !== '0') {
      this._notes
        .postNote(note_to_save, this.company_id, null, null, null)
        .subscribe(
          (data: any) => {
            this.fetchNotes();
            this._snackBar.open('Note added', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (error: any) => {
            this._snackBar.open('Error!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        );
    }
    if (this.application_id && this.application_id !== '0') {
      this._notes
        .postNote(note_to_save, null, this.application_id, null, null)
        .subscribe(
          (data: any) => {
            this.fetchNotes();
            this._snackBar.open('Note added', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (error: any) => {
            this._snackBar.open('Error!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        );
    }
    if (this.elearning_id && this.elearning_id !== '0') {
      this._notes
        .postNote(note_to_save, null, null, this.elearning_id, null)
        .subscribe(
          (data: any) => {
            this.fetchNotes();
            this._snackBar.open('Note added', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (error: any) => {
            this._snackBar.open('Error!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        );
    }
    if (this.support_id && this.support_id !== '0') {
      this._notes
        .postNote(note_to_save, null, null, null, this.support_id)
        .subscribe(
          (data: any) => {
            this.fetchNotes();
            this._snackBar.open('Note added', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          },
          (error: any) => {
            this._snackBar.open('Error!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        );
    }
  }

  autoSaveNotes(): void {
    var note_to_save_array = this.notes.filter((x: any) => !x.saved);
    var note_to_save = note_to_save_array[0].note;

    for (let note of this.notes) {
      note.saved = true;
    }

    this.saveNotes(note_to_save);
  }

  saveNoteClick(): void {
    this.selectedNote.saved = true;
    this.saveNotes(this.selectedNote.note);
  }
}
