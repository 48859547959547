import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Component, AfterViewInit, Input, ViewChild } from '@angular/core';
import { MasterLicenseService } from '../../_services/masterlicense.service';
import { MasterLicenseModalComponent } from '../master-license-modal/master-license-modal.component';
import { ConfirmPopupModalComponent } from '../../shared/confirm-popup-modal/confirm-popup-modal.component';

@Component({
  selector: 'app-expired-master-licenses',
  templateUrl: './expired-master-licenses.component.html',
  styleUrls: ['./expired-master-licenses.component.scss'],
})
export class ExpiredMasterLicensesComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  @Input('company') company_id: string = '1';

  offset = 0;
  pageSize = 10;
  totalRows = 0;
  currentPage = 0;

  loading = true;
  isLoading = true;

  isSearchFormSubmitted = false;
  searchMasterLicense: any = '';

  public apps_list: any[];
  public table_data!: any[];
  public modules_available!: any[];

  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.table_data
  );

  public displayedColumns = [
    'module',
    'issue',
    'expiry',
    'max_users',
    'current_users',
    'status',
    'payment',
    'actions',
  ];

  constructor(
    private _masterLicense: MasterLicenseService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.getMasterLicense();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchMasterLicense = this.searchMasterLicense.trim();
    this.getMasterLicense();
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchMasterLicense = '';
      return;
    }

    this.searchMasterLicense = '';
    this.getMasterLicense();
    this.isSearchFormSubmitted = false;
  }

  getMasterLicense(): void {
    const datePipe = new DatePipe('en-US');
    this.loading = true;
    this._masterLicense
      .getMasterLicense(
        parseInt(this.company_id),
        this.pageSize,
        this.offset,
        this.searchMasterLicense,
        'master_license',
        false
      )
      .subscribe((data: any) => {
        this.table_data = data.detail.result;

        this.modules_available = [];
        for (let m of data.detail.result) {
          const formattedExpiry = m.expiry
            ? datePipe.transform(new Date(m.expiry), 'yyyy-MM-dd')
            : 'Indefinite';

          this.modules_available.push({
            name: m.module.name,
            uuid: m.module.application_uuid,
            id: m.module.id,
            image: m.module_icon,
            expiry: formattedExpiry,
            user_count: m.assigned_licenses_count,
          });
        }

        this.dataSource.data = this.table_data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.detail.count;
        });
      });
  }

  checkActive(id: string): boolean {
    let mlic = this.table_data.filter((x: any) => x.id === parseInt(id))[0];

    let date_now = new Date();
    let expiry_date = new Date(mlic.expiry);

    if (mlic.expiry) {
      let expiry_date = new Date(mlic.expiry);
      if (expiry_date < date_now) {
        return false;
      }
    }

    if (
      mlic.maximum_issue &&
      mlic.maximum_issue < mlic.assigned_licenses_count
    ) {
      return false;
    }

    return mlic.active;
  }

  editLicense(element: any) {
    const dialogRef = this._dialog.open(MasterLicenseModalComponent, {
      width: '600px',
      data: {
        module: {
          name: element.module.name,
          uuid: element.module.application_uuid,
          id: element.id,
        },
        expiry: element.expiry,
        maximum_issue: element.maximum_issue,
        payment_method: element.payment_method.name,
        id: element.id,
        modules_available: this.modules_available,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        element.expiry = result.data.expiry;
        element.maximum_issue = result.data.maximum_issue;
        element.payment_method = result.data.payment_method;
        let payload = {
          expiry: element.expiry,
          maximum_issue: element.maximum_issue,
          payment_method: element.payment_method,
        };
        this._masterLicense.updateMasterLicense(element.id, payload).subscribe(
          (data: any) => {
            this.getMasterLicense();
            this._snackBar.open('License updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._snackBar.open('Error license not updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          }
        );
      }
    });
  }

  suspendLicense(element: any) {
    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: element.id,
        title: 'Suspend License',
        delete: 'License',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        let payload = {
          isHidden: true,
        };
        let now = new Date();
        element.expiry = now;
        element.maximum_issue = 0;

        let l_payload = {
          active: false,
        };
        this._masterLicense
          .updateMasterLicense(element.id, l_payload)
          .subscribe(
            (data: any) => {
              this.getMasterLicense();
              this._snackBar.open('License suspended.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open('Error! license not suspended.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            }
          );
      }
    });
  }

  deleteLicense(element: any) {
    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: element.id,
        title: 'Delete License',
        delete: 'License',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this._masterLicense.deleteMasterLicense(element.id).subscribe(
          (data: any) => {
            this.getMasterLicense();
            this._snackBar.open('License deleted.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._snackBar.open('Error! license not dele.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          }
        );
      }
    });
  }

  activateLicense(element: any) {
    let now = new Date();
    element.expiry = now;
    element.maximum_issue = 0;

    let l_payload = {
      active: true,
    };
    this._masterLicense.updateMasterLicense(element.id, l_payload).subscribe(
      (data: any) => {
        this.getMasterLicense();
        this._snackBar.open('License activated.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        });
      },
      (error: any) => {
        this._snackBar.open('Error! license not activated.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        });
      }
    );
  }

  pageChanged(event: PageEvent, isActive: boolean): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getMasterLicense();
  }
}
