<div fxLayout="row wrap">
  <div fxFlex="100" class="m-b-20" style="text-align: right" align="end">
    <button mat-raised-button color="primary" (click)="newLMS()">
      <mat-icon>post_add</mat-icon> Add LMS
    </button>
  </div>
</div>
<mat-progress-bar
  mode="indeterminate"
  class="m-t-20"
  *ngIf="loading"
></mat-progress-bar>
<ng-container *ngIf="platformData">
  <input
    type="file"
    style="display: none"
    #fileUpload
    (change)="onFileSelected($event)"
  />
  <ng-conatiner *ngFor="let lms of platformData; let ii = index">
    <div fxLayout="row wrap">
      <div fxFlex="100" class="m-b-30">
        <h3>{{ lms.name }} ({{ lms.status }})</h3>
        <mat-divider
          style="margin-top: 0; padding-top: 0"
          inset="true"
        ></mat-divider>
      </div>
      <div fxFlex="100" class="m-b-20" style="text-align: right" align="end">
        <button
          mat-raised-button
          color="primary"
          (click)="formEdit[0] = !formEdit[ii]"
          *ngIf="!formEdit[ii]"
          style="margin-right: 10px;"
        >
          <mat-icon>edit</mat-icon> Edit Settings
        </button>
        <button mat-mini-fab color="warn" (click)="deleteLms(lms)">
          <i class="fa fa-trash"></i>
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="updateLMS(lms); formEdit[ii] = !formEdit[ii]"
          *ngIf="formEdit[0]"
        >
          <mat-icon>save</mat-icon> Save Settings
        </button>
      </div>
      <div fxFlex="30" class="p-r-10">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="lms.name"
            [readonly]="!formEdit[ii]"
          />
        </mat-form-field>
      </div>
      <div fxFlex="70">
        <mat-form-field>
          <mat-label>Domain</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="lms.domain"
            [readonly]="!formEdit[ii]"
          />
        </mat-form-field>
      </div>
      <div fxFlex="40" class="p-r-10">
        <mat-form-field>
          <mat-label>Plan Expiry</mat-label>
          <input
            matInput
            [matDatepicker]="datepicker"
            [(ngModel)]="lms.plan_expiry"
            [readonly]="!formEdit[ii]"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="30" class="p-r-10">
        <mat-form-field>
          <mat-label>Users Est.</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="lms.user_estimate"
            [readonly]="!formEdit[ii]"
          />
        </mat-form-field>
      </div>
      <div fxFlex="30">
        <mat-form-field>
          <mat-label>Users Act.</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="lms.user_actual"
            [readonly]="!formEdit[ii]"
          />
        </mat-form-field>
      </div>
      <div fxFlex="35" class="p-r-10">
        <mat-form-field>
          <mat-label>Hosting Plan</mat-label>
          <mat-select
            [(ngModel)]="lms.hosting_model"
            [disabled]="!formEdit[ii]"
          >
            <mat-option *ngFor="let t of hostingOptions" [value]="t">{{
              t
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="35" class="p-r-10">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="lms.status" [disabled]="!formEdit[ii]">
            <mat-option *ngFor="let t of statusOptions" [value]="t">{{
              t
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="30">
        <mat-form-field>
          <mat-label>Server</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="lms.server"
            [readonly]="!formEdit[0]"
          />
        </mat-form-field>
      </div>
      <div fxFlex="100">
        <p>
          The display settings for {{ companyName }} are shown below. Colours
          can be selected using the colour picker or by entering the Hex code.
          Colour changes will not automatically update please raise a ticket to
          update colour schemes.
        </p>
      </div>
      <div
        fxFlex="33.33"
        align="center"
        class="color-card"
        style="padding-right: 20px"
      >
        <h4>Primary Colour</h4>
        <mat-card>
          <mat-card-content
            style="height: 40px"
            [ngStyle]="{ 'background-color': lms.primary_colour }"
          >
          </mat-card-content>
        </mat-card>
        <div style="margin-top: 16px">
          <ngx-colors
            ngx-colors-trigger
            [(ngModel)]="lms.primary_colour"
            (input)="onColourChange(lms)"
          >
          </ngx-colors>
        </div>
      </div>
      <div
        fxFlex="33.33"
        align="center"
        class="color-card"
        style="text-align: center; padding-right: 20px; padding-left: 20px"
      >
        <h4>Accent Colour</h4>
        <mat-card>
          <mat-card-content
            style="height: 40px"
            [ngStyle]="{ 'background-color': lms.accent_colour }"
          >
          </mat-card-content>
        </mat-card>
        <div style="margin-top: 16px">
          <ngx-colors
            ngx-colors-trigger
            [(ngModel)]="lms.accent_colour"
            (input)="onColourChange()"
          >
          </ngx-colors>
        </div>
      </div>
      <div
        fxFlex="33.33"
        align="center"
        class="color-card"
        style="text-align: center; padding-left: 20px"
      >
        <h4>Warn Colour</h4>
        <mat-card>
          <mat-card-content
            style="height: 40px"
            [ngStyle]="{ 'background-color': lms.warn_colour }"
          >
          </mat-card-content>
        </mat-card>
        <div style="margin-top: 16px">
          <ngx-colors
            ngx-colors-trigger
            [(ngModel)]="lms.warn_colour"
            (input)="onColourChange()"
          >
          </ngx-colors>
        </div>
      </div>
      <div
        fxFlex="100"
        class="m-b-20 m-t-20"
        style="text-align: right"
        align="end"
      >
        <button
          mat-raised-button
          color="primary"
          (click)="uploadFile(lms)"
          *ngIf="!formEdit[0]"
        >
          <mat-icon>cloud_upload</mat-icon> Add File
        </button>
      </div>
      <div fxFlex="100" class="m-t-20">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Artwork </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row wrap">
              <ng-container *ngFor="let item of lms.artwork_links">
                <div fxFlex="40">
                  {{ item.name }}
                </div>
                <div fxFlex="60">
                  {{ item.url }}
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Content </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row wrap">
              <ng-container *ngFor="let item of lms.content_links">
                <div fxFlex="40">
                  {{ item.name }}
                </div>
                <div fxFlex="60">
                  {{ item.url }}
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-conatiner>
</ng-container>
