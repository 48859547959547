import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() userDetail: any;
  @Input() optionKey: any;
  @Input() size = 'large';
  roles: string = "";
  constructor() {}

  ngOnInit(): void {    
    
    if(this.optionKey === 'first_name'){

      this.roles = this.userDetail?.user_roles_human?.filter((n: string) => n)
      .join(", ");
    }
    if(this.optionKey === 'group_name') {

      this.roles =  this.userDetail?.manager?.user_roles_human
      .filter((n: string) => n)
      .join(", ");
    }
  }
}
