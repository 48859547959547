<mat-dialog-content class="mat-typography">
  	<div fxLayout="row wrap">
      	<div fxFlex="100" style="text-align:right;">
          	<button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
    	</div> 
		<div fxFlex="100">
         	<h3>Update Order</h3>
 		</div>
 		<div fxFlex="100">
        	<mat-form-field>
          		<mat-label>Carrier</mat-label>
            	<mat-select [ngModel]="data.carrier.id" (ngModelChange)="onChange($event)">
    				<mat-option *ngFor="let t of data.carriers" [value]="t.id">{{ t.name }}</mat-option>
  				</mat-select>
        	</mat-form-field>
    	</div>
    	<div fxFlex="100">
        	<mat-form-field>
          		<mat-label>Tracking Reference</mat-label>
          		<input matInput type="text" [(ngModel)]="data.tracking_reference">
          	</mat-form-field>
      	</div>
      	<div fxFlex="100">
        	<button mat-raised-button color="primary" (click)="closeDialog()" style="width:100%; margin-top: 16px;"><mat-icon>local_shipping</mat-icon>Update Shipping</button>
      	</div>
 	</div>

</mat-dialog-content>
