
<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<!--<button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-5">
    <mat-icon>message</mat-icon>
    <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button>
<mat-menu #notification="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Notifications</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                         Message -->
                       <!-- <a href="#" *ngFor="let notification of notifications">
                            <div class="round {{notification.round}}">
                                <i class="{{notification.icon}}"></i>
                            </div>
                            <div class="mail-content">
                                <h5>{{notification.title}}</h5>
                                <span class="mail-desc">{{notification.subject}}</span>
                                <span class="time">{{notification.time}}</span>
                            </div>
                        </a>

                    </perfect-scrollbar>
                </div>
            </li>

        </ul>
    </div>
</mat-menu>-->

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <img  alt="user" class="profile-pic">
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <mat-menu #profile="matMenu" class="mymegamenu">
        <a mat-menu-item [routerLink]="['/user/modules/']">
            <mat-icon>settings</mat-icon> Modules
        </a>
        <a mat-menu-item [routerLink]="['/user/myprofile/']">
            <mat-icon>account_box</mat-icon> Profile
        </a>
        <button mat-menu-item (click)="logOut();">
            <mat-icon>exit_to_app</mat-icon> Sign Out
        </button>
    </mat-menu>
</mat-menu>