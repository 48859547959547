import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit {

  constructor
    (
      public _dialogRef: MatDialogRef<AddUserModalComponent>,
      private _user: UserService,
      private _snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    this.getUserActiveLicense(this.data?.user);

    if (this.data.user.user_roles_human.length != 0) {
      this.data.user.role = this.data.roles.filter(
        (x: any) =>
          x.name.toLowerCase() ===
          this.data.user.user_roles_human[0].toLowerCase()
      )[0].id;
    }
  }

  getUserActiveLicense(element: any): void {
    this._user.userActiveLicense(element.id).subscribe((data: any) => {
      let active_licenses = data.detail.result;
      
      let licenses_id = [];
      for (let lic of active_licenses) {
        licenses_id.push(lic.id);
      }

      this.data.user.active_licenses_uuid = licenses_id;
    });
  }

  editUser() {
    this.data.user.active_licenses = [];

    // for (let l of this.data.user.active_licenses_uuid) {
    // this.data.user.active_licenses.push(
    // this.data.licenses.filter(
    //   (x: any) => x.application_uuid === l
    // )[0]
    // );
    // }

    const filteredLicenses: any[] = this.data.licenses.filter((lic: any) =>
      this.data.user.active_licenses_uuid.includes(lic.id)
    );

    var license_array: any[] = [];
    for (let lic of filteredLicenses) {
      if (lic.assigned_licenses_count >= lic.maximum_issue) {
        this._snackBar.open(`"${lic.module.name}" License limit exceeded. Please contact Xelerate Administrator for more licenses`, "Dismiss", {
          horizontalPosition: "center",
          verticalPosition: "top",
          duration: 2000,
        });
        this._dialogRef.close();
        return
      } else {
        license_array.push(lic.id);
      }
    }

    this.data.user.active_licenses = license_array;

    this._user
      .updateUserCompany(
        this.data.user.id,
        this.data.user.first_name,
        this.data.user.role,
        license_array,
        this.data.user.mobile,
        this.data.user.telephone,
        this.data.user.license_bundles
      )
      .subscribe(
        (data: any) => {
          this._snackBar.open("User updated.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
          this.triggerChange({ data: this.data.user });
          this._dialogRef.close();
        },
        (error: any) => {
          let errorMessage = error.error.message || "Error! User not updated."
          this._snackBar.open(errorMessage, "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        }
      );
  }

  private triggerChange(param: any = null): void {
    if (typeof this.data.onChange === "function") {
      this.data.onChange(param);
    }
  }

  ngOnInit(): void {
  }

}
