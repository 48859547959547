import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubdomainService } from '../../_services/subdomain.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SubdomainModalComponent } from '../subdomain-modal/subdomain-modal.component';
import { ConfirmPopupModalComponent } from '../confirm-popup-modal/confirm-popup-modal.component';

@Component({
  selector: 'app-subdomains',
  templateUrl: './subdomains.component.html',
  styleUrls: ['./subdomains.component.scss'],
})
export class SubdomainsComponent implements OnInit {
  public table_data!: any[];
  public displayedColumns = [
    'subdomain',
    'domain',
    'restricted',
    'custom',
    'server',
    'created',
    'actions',
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.table_data
  );

  pageSize = 10;
  currentPage = 0;
  offset = 0;
  totalRows = 0;
  isSearchFormSubmitted = false;
  searchSubDomain: any = "";

  @Input('company') company_id: string = '1';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _subdomain: SubdomainService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.getSubDomains();
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getSubDomains();
  }

  getSubDomains() :void {
    this._subdomain
    .getSubDomains(parseInt(this.company_id), this.searchSubDomain)
    .subscribe((data: any) => {
      this.table_data = data.detail.result;
      this.dataSource.data = this.table_data;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = data.detail.count;
      });
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getDomainType(domain: string) {
    if (domain.includes('e-learningstudios')) {
      return 'E-Learning Studios';
    }

    if (domain.includes('xelerate')) {
      return 'Xelerate';
    }

    if (domain.includes('xrlplatform')) {
      return 'XRL Platform';
    }
  }

  addSubDomain() {
    const dialogRef = this._dialog.open(SubdomainModalComponent, {
      width: '600px',
      data: {
        id: null,
        subdomain: null,
        domain: null,
        description: null,
        restricted: false,
        custom: false,
        server: null,
        url: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this._subdomain
          .createSubDomains(
            parseInt(this.company_id),
            result.data.subdomain,
            result.data.domain,
            result.data.custom,
            result.data.restricted,
            result.data.description
          )
          .subscribe(
            (data: any) => {
              this.getSubDomains();
              this._snackBar.open('Sub domain added.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open('Error sub domain not added.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
              this.getSubDomains();
            }
          );
      }
    });
  }

  editSubDomain(element: any) {
    const dialogRef = this._dialog.open(SubdomainModalComponent, {
      width: '600px',
      data: {
        id: element.id,
        subdomain: element.url.split('.')[0],
        domain: this.getDomainType(element.url),
        description: element.description,
        restricted: element.restricted,
        custom: element.custom,
        server: element.server,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        
        this._subdomain
          .updateSubDomains(
            parseInt(element.id),
            result.data.custom,
            result.data.restricted,
            result.data.description
          )
          .subscribe(
            (data: any) => {
              this.getSubDomains();
              this._snackBar.open('Sub domain updated.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open('Error sub domain not updated.', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              });
              this.getSubDomains();
            }
          );
      }
    });
  }

  deleteSubDomain(element: any) {

    const dialogRef = this._dialog.open(ConfirmPopupModalComponent, {
      width: '400px',
      data: {
        id: element.id,
        title: 'Delete Sub Domain',
        delete: 'subdomain',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.data) {
        this.table_data = this.table_data.filter(
          (x: any) => x.id !== element.id
        );
        this.dataSource.data = this.table_data;
        this._subdomain.deleteSubDomains(element.id).subscribe(
          (data: any) => {
            this._snackBar.open('Sub domain deleted.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this._snackBar.open('Error sub domain not delete.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
            this.getSubDomains();
          }
        );
      }
    });
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchSubDomain = "";
      return;
    }

    this.searchSubDomain = "";
    this.getSubDomains();
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchSubDomain = this.searchSubDomain.trim()
    this.getSubDomains();
  }
}
