import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { UserService } from '../../_services/user.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-create-supplier-order',
  templateUrl: './create-supplier-order.component.html',
  styleUrls: ['./create-supplier-order.component.scss'],
})
export class CreateSupplierOrderComponent implements OnInit {
  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<CreateSupplierOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this._dialogRef.close({ data: this.data });
  }
}
