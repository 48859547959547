import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupManagerModalComponent } from '../group-manager-modal/group-manager-modal.component';
import { ConfirmPopupModalComponent } from '../confirm-popup-modal/confirm-popup-modal.component';
import { GroupsService } from '../../_services/groups.service';
import { MoveUserAnotherGroupComponent } from '../move-user-another-group/move-user-another-group.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MergeGroupModalComponent } from '../merge-group-modal/merge-group-modal.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-group-manager',
  templateUrl: './group-manager.component.html',
  styleUrls: ['./group-manager.component.scss'],
})
export class GroupManagerComponent implements OnInit, OnChanges, AfterViewInit {
  GROUP_MANAGER_ROLES = ['Company Learner', 'Els Developer'];
  addGroupButton = true;
  loading = true;
  @Input('group') AvailableGroup: any[] = [];
  @Input('company') companyId: string;
  totalData = 0;
  totalRows = 0;
  public selectableDataSource: any[] = [];
  isLoading = false;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  userDetails: any;

  isSearchFormSubmitted = false;
  searchGroup: any = "";
  public isRoleLearner = this.authService.isRoleLearner;

  public tableData!: any[];
  public AvailableUser!: any[];
  public CompanyGroup!: any[];
  public displayedColumns = [
    'select',
    'name',
    'groupManager',
    'userCount',
    'createdAt',
    'action',
  ];

  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.tableData
  );
  public selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private groupService: GroupsService,
    protected authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.authService.isRoleLearner) {
      this.displayedColumns = [
        'name',
        'groupManager',
        'userCount',
        'createdAt',
      ];
    }
  }

  ngOnChanges(): void {
    if (this.companyId) {
      this.getGroup();

      if (!this.authService.isRoleLearnerDev) {
        this.getComapnayManager();
      }
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getGroup(): void {
    this.loading = true;
    let data: any = {
      company: parseInt(this.companyId),
      page_size: this.pageSize,
    };
    if (this.searchGroup) {
      data.search_params = this.searchGroup;
    }
    this.groupService.getComapnyGroup(data, this.offset).subscribe(
      (data: any) => {
        this.CompanyGroup = data.detail.result;
        this.dataSource.data = this.CompanyGroup;
        let selectedData = [...this.CompanyGroup];
        this.selectableDataSource = selectedData.filter((data) => {
          if (data.can_edit) {
            return data;
          }
        });
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.detail.count;
        });

        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  getComapnayManager(): void {
    this.loading = true;
    this.groupService.getComapnyManager(parseInt(this.companyId)).subscribe(
      (data: any) => {
        this.AvailableUser = data.detail.result;
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getGroup();
  }

  isAllSelected(): boolean {    
    const numSelected = this.selection.selected.length;
    const numRows = this.selectableDataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {

    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.selectableDataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  checkCan_edit(row: any) {
    return !row.can_edit;
  }

  addGroup(): void {
    const dialogRef = this.dialog.open(GroupManagerModalComponent, {
      width: "600px",
      data: {
        id: null,
        group_name: null,
        manager: null,
        users: this.AvailableUser,
        companyId: this.companyId,
        newUser: [],
        onChange: (params: any = null) => {
          this.getGroup();
        },
      },
    });
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(GroupManagerModalComponent, {
      width: "600px",
      data: {
        id: element.id,
        group_name: element.group_name,
        manager: element.manager,
        companyId: this.companyId,
        users: this.AvailableUser,
        newUser: [],
        userdelete: false,
        groupDetail: element,
        onChange: (params: any = null) => {
          this.getGroup();
        },
      },
    });
  }

  deleteGroup(element: any): void {
    const dialogRef = this.dialog.open(ConfirmPopupModalComponent, {
      width: "400px",
      data: {
        id: element.id,
        title: "Delete Group",
        delete: "group",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this.isLoading = true;
        this.groupService
          .deleteGroup(parseInt(result?.data.id), { users: [] })
          .subscribe(
            (data: any) => {
              let newGroupList: any[] = [];
              this.CompanyGroup.map((group) => {
                if (group.id != result.data.id) {
                  newGroupList.push(group);
                }
              });
              this.CompanyGroup = newGroupList;
              this.dataSource.data = newGroupList;
              setTimeout(() => {
                this.paginator.pageIndex = this.currentPage;
              });
              this.snackBar.open("Group delete successfully.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
                panelClass: 'app-notification-success',
              });
              this.isLoading = false;
            },
            (error: any) => {
              this.isLoading = false;
              let errorMessage = error?.error?.message
                ? error?.error?.message
                : error?.error?.detail?.group_name[0];
              if (errorMessage) {
                this.snackBar.open(errorMessage, "Dismiss", {
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: 'app-notification-error',

                });
              }
            }
          );
      }
    });
  }

  moveUser(): void {
    const dialogRef = this.dialog.open(MoveUserAnotherGroupComponent, {
      width: "600px",
      data: {
        companyId: this.companyId,
        companyGroup: this.CompanyGroup,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this.isLoading = true;
        let groupData = {
          company: this.companyId,
          users: result?.data.moveUser,
          new_group_id: result?.data.moveGroup.targetGroup,
        };
        this.groupService
          .updateGroup(parseInt(result?.data.moveGroup.sourceGroup), groupData)
          .subscribe(
            (data: any) => {
              if (data.detail) {
                this.getGroup();
                this.snackBar.open("User move Group Successfully.", "Dismiss", {
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  duration: 2000,
                  panelClass: 'app-notification-success',
                });
              }
              this.isLoading = false;
            },
            (error: any) => {
              this.isLoading = false;
              let errorMessage = error?.error?.message
                ? error?.error?.message
                : error?.error?.detail?.group_name[0];
              if (errorMessage) {
                this.snackBar.open(errorMessage, "Dismiss", {
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: 'app-notification-error',
                });
              }
            }
          );
      }
    });
  }

  mergeGroup(): void {
    const dialogRef = this.dialog.open(MergeGroupModalComponent, {
      width: "600px",
      data: {
        companyId: this.companyId,
        selectedGroup: this.selection.selected,
        AvailableManager: this.AvailableUser,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data) {
        this.selection.clear();
        this.getGroup();
      }
    });
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchGroup = "";
      return;
    }

    this.searchGroup = "";
    this.getGroup();
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchGroup = this.searchGroup.trim()
    this.getGroup();
  }
}
