import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-headset-modal',
  templateUrl: './headset-modal.component.html',
  styleUrls: ['./headset-modal.component.scss']
})
export class HeadsetModalComponent implements OnInit {

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<HeadsetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  getMinQuantity(id: number){

    // let headsets_available = this.data.headsets_available.filter((x:any) => x.id == id);
    // return headsets_available[0].min_quantity
  }

  closeDialog(id: number){
    this.data.headset.headset_name.name = this.data.headsets_available.filter((x:any) => x.id === id)[0].name
    this._dialogRef.close({data: this.data});
  }

}
