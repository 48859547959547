<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
      <div fxFlex="100">
        <div fxFlex="80" style="text-align: left">
          <h3>Add LMS</h3>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="data.name">
          </mat-form-field>
      </div>

      <div fxFlex="100" class="m-t-20">
        <button mat-raised-button color="primary" style="width:100%" (click)="newLMS()"><mat-icon>post_add</mat-icon> Add LMS</button>
      </div>
    </div>
</mat-dialog-content>
