<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap">
      <div fxFlex="100" style="text-align:right;">
          <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div> 
      <div fxFlex="100">
         <h3>Upload File</h3>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="data.name">
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field>
          <mat-label>Type of Work</mat-label>
            <mat-select [(ngModel)]="data.type_of_work">
    			<mat-option *ngFor="let t of contentTypes" [value]="t.slug">{{ t.name }}</mat-option>
  			</mat-select>
          </mat-form-field>
      </div>
      <div fxFlex="100">
        <button mat-raised-button color="primary" style="width:100%" (click)="closeDialog()"><mat-icon>upload</mat-icon>Upload File</button>
      </div>
    </div>
</mat-dialog-content>
