import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'app-render-list-value',
  templateUrl: './render-list-value.component.html',
  styleUrls: ['./render-list-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderListValueComponent implements OnInit, OnChanges {
  @Input() value!: boolean;
  isExpanded = false;
  list:any[] = [];
  viewList = '';
  private readonly MAX_ITEMS_TO_DISPLAY = 3;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      this.list = changes.value.currentValue || [];
      this.setList();
    }
  }

  toggleExpandable(): void {
    this.isExpanded = !this.isExpanded;
    this.setList();
  }

  private setList(): void {
    if (this.isExpanded) {
      this.viewList = this.list.join(',');
    } else {
      this.viewList = this.list.slice(0, this.MAX_ITEMS_TO_DISPLAY).join(',');
    }
  }

  get isExpandable(): boolean {
    return this.list.length > this.MAX_ITEMS_TO_DISPLAY;
  }
}
